<template>
    <div class="page text-dark">
        <NavBar :navigation="navList" />
        <banner :banner="bannerList" />
        <about :title="navList.about" :about="aboutList" />
        <features :title="navList.features" :features="featureList" />
        <services :title="navList.services" :services="servicesList" />
        <contacts :title="navList.contact" :contacts="contactsList" />
        <footer-bar :footer="footerList" />
        <UKDialog />

        <!-- jump to the top -->
        <button
            v-if="showScrollBtn"
            @click="goTop"
            class="fixed bottom-24px right-24px bg-dark w-38px h-38px rounded-1/2 border-2px border-light flex items-center justify-center hover:(cursor-pointer bg-primary) active:bg-secondary"
        >
            <img :src="require('../../img/chever.svg')" />
        </button>
    </div>
</template>
<script>
import NavBar from '@/components/NavBar/index.vue'
import { staticData } from '../data.js'

export default {
    name: 'Home',
    components: {
        NavBar,
        UKDialog : () => import('./Dialog/UKDialog.vue'),
        banner: () => import('./Banner/index.vue'),
        about: () => import('./About/index.vue'),
        features: () => import('./Features/index.vue'),
        services: () => import('./Services/index.vue'),
        contacts: () => import('./Contacts/index.vue'),
        footerBar: () => import('./Footer/index.vue')
    },
    mounted() {
        window.addEventListener('scroll', this.scrollPage)
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.scrollPage)
    },
    data() {
        return {
            ...staticData,
            showScrollBtn: false
        }
    },
    methods: {
        scrollPage() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                this.showScrollBtn = true
            } else {
                this.showScrollBtn = false
            }
        },
        // to scroll up to the page
        goTop() {
            document.body.scrollTop = 0 // For Safari
            document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
        }
    }
}
</script>
<style></style>
