<template>
    <div class="page text-dark bg-light">
        <NavBar :navigation="navList" @nav="goTop" />

        <div
            class="w-full px-120px pt-48px mt-72px pb-92px ipad:(flex flex-col px-24px) mobile:(w-full flex flex-col py-32px px-16px mt-72px)"
        >
            <div class="sf_18 flex gap-16px h-24px items-center text-base-16px tracking-0.003em">
                <router-link to="/tc" class="link text-neutral text-base-16px font-510">
                    Terms and Conditions
                </router-link>
                <span class="bg-lineSecondary w-2px h-24px"></span>
                <span class="font-510">Privacy and Policy</span>
            </div>
            <div
                class="section_headline text-dark mt-40px ipad:(flex justify-center) mobile:(leading-48px text-base-40px text-center flex justify-center)"
            >
                <span class="ligne">Privacy and Policy</span>
            </div>
            <div class="sf_18 text-base-16px tracking-0.003em font-510 leading-24px mt-20px">
                <p>
                    This Privacy Policy describes how SaintPay S.R.L (“we”, “us”, “our”,
                    “ourselves”, “SaintPay”) collects and processes personal data of our potential,
                    existing or former clients, our client’s employees, or other parties (“you”,
                    “your”) through the SaintPay websites and applications that reference this
                    Privacy Policy. By using the services provided by us (“Services”), you are
                    consenting to the collection, storage, processing, and transfer of your personal
                    data as described in this Privacy Policy.
                </p>
                <p>
                    SaintPay S.R.L is a cryptocurrency exchange operator, established under the laws
                    of the Republic of Italy, registration number 12902640965, having its registered
                    office at Milano (Mi) Via Del Lauro 9 Cap 20121. SaintPay is the data controller
                    for personal data collected and processed in connection with the provision of
                    Services.
                </p>
                <p class="title mt-40px">SCOPE OF OUR PRIVACY POLICY</p>
                <p class="tracking-0.5px leading-28px">
                    1.1. This Privacy Policy describes how SaintPay collects, uses, stores, shares,
                    and protects your personal data whenever you use Services through our website
                    (“Website”), our mobile app (“Mobile App”) or by corresponding with us (for
                    example by email or by filling messaging forms on the Website).
                    <br />
                    <br />
                    1.2. Personal data collected by us are processed in accordance with the Codice
                    In Materia Di Protezione Dei Dati Personali in the Republic of Italy, the EU
                    General Data Protection Regulation No. 2016/679 (“GDPR”), and other legal acts.
                    All employees, agents, and employees of the agents of SaintPay who know the
                    secret of personal data must keep it safe even after termination of the
                    employment or contractual relationship.
                    <br />
                    <br />
                    1.3. For the processing of personal data, SaintPay may engage data processors
                    and/or, at its sole discretion, hire other persons to perform certain functions
                    on behalf of us. In such cases, we shall take necessary measures to ensure that
                    such data is processed by the personal data processors in accordance with our
                    instructions and applicable legislation. SaintPay shall also require the
                    personal data processors to implement appropriate measures for the security of
                    personal data. In such cases, SaintPay shall ensure that such persons will be
                    subject to the non-disclosure obligation and will not be able to use this
                    information for any other purpose, except to the extent necessary to perform the
                    functions assigned to them.
                    <br />
                    <br />
                    1.4. We assume that you have carefully read this document and accepted it. If
                    you do not agree with this Privacy Policy, then you should refrain from using
                    our Services or opening an account. This Privacy Policy is an integral part of
                    our Terms of Use.
                    <br />
                    <br />
                    1.5. We may change this Privacy Policy from time to time. We will post any
                    Privacy Policy changes on the Website and additionally send you an email
                    informing about changes made. Continued use of SaintPay’s Website and/or
                    Services implies your acceptance of the revised Privacy Policy.
                    <br />
                    <br />
                    1.6 SaintPay respects the individual’s right to privacy and makes all reasonable
                    efforts to ensure the security and confidentiality of personal data and other
                    information processed on our Website and Mobile App.
                    <br />
                    <br />
                    1.7. You can visit our Website without providing any information about yourself,
                    however, if you want to open an account in order to access our services
                    (“Account”), and/or use other payment services offered by us, we will ask you to
                    provide personal data indicated in the system and to carry out established
                    identification procedures.
                    <br />
                    <br />
                    1.8. SaintPay reserves the right, at its sole discretion to alter the provisions
                    of the present Privacy Policy, therefore, when visiting this Website, you have a
                    responsibility to make sure that you are familiar with the latest version of the
                    Privacy Policy that applies to you at the time you are visiting the Website.
                </p>
                <p class="title mt-40px">PERSONAL DATA WE COLLECT</p>
                <p>
                    2.1. General To provide you with SaintPay Account and Services thereof, SaintPay
                    is bound by law to establish and verify your identity prior to entering
                    financial services transactions with you, also, at the time of the provision of
                    the Services, to request further information, as well as assess and store this
                    information for the retention period set out by legislation. Taking this into
                    account, you must provide correct and complete information. Personal data is
                    collected and used during 3 principal steps: registration, identity verification
                    and the use of SaintPay Account and Services.
                    <br />
                    <br />
                    2.2. Personal data categories The personal data we collect can be grouped into
                    the following categories: 
                </p>
                <table class="gridtable">
                    <thead>
                        <th>Type of information</th>
                        <th>Personal data </th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1.Basic personal data </td>
                            <td>First, last, middle, maiden names, job title, etc. </td>
                        </tr>
                        <tr>
                            <td>
                                2.Identification information and other background verification data
                            </td>
                            <td>
                                Name, surname, date of birth, personal code, address, copy of
                                identification document (ID/Passport/Itinerary document) and its
                                details (expiry date, number), nationality, occupation, evidence of
                                beneficial ownership or the source of funds, source of wealth
                                (information on how wealth was obtained), tax information (tax
                                residence, tax identification number), number of shares held, voting
                                rights or part of share capital, title, account number, photo,
                                signature.
                            </td>
                        </tr>
                        <tr>
                            <td>3.Monetary operation details</td>
                            <td>
                                History of transactions (currency, amount, location, date,
                                information of payer and payee, i.e., name, surname, account number,
                                purpose of transaction, number of transactions, message content).
                            </td>
                        </tr>
                        <tr>
                            <td>4.Details of your activities in our Mobile App</td>
                            <td>
                                Login history, history and other information of your actions while
                                using Mobile app, device geolocation, IP address, device info (name,
                                model, operating system, unique ID).
                            </td>
                        </tr>
                        <tr>
                            <td>5.Details of your activities in your website account</td>
                            <td>
                                History of the actions performed in your Website account, technical
                                information, including the internet protocol (IP) address used to
                                connect your computer to the internet, your log-in information
                                (e.g., login time), browser type and version, time-zone setting,
                                operating system and platform, type of device you use, unique device
                                identifier. 
                            </td>
                        </tr>
                        <tr>
                            <td>6.Details of your activities in our website </td>
                            <td>
                                History of the actions performed in our Website, technical
                                information, including the internet protocol (IP) address used to
                                connect your computer to the internet, browser type and version,
                                time zone setting, operating system and platform, type of device you
                                use.
                            </td>
                        </tr>
                        <tr>
                            <td>7.Details of your existing bank account/-s </td>
                            <td>
                                Financial institution account number, IBAN number, payment card
                                number, date of issue and expiry date.
                            </td>
                        </tr>
                        <tr>
                            <td>8.Information related to legal requirements </td>
                            <td>
                                Data that enables us to perform anti-money laundering requirements
                                and ensure the compliance with international sanctions, including
                                the purpose of the business relationship and whether you are a
                                politically exposed person and other data that is required to be
                                processed by us in order to comply with the legal obligation to
                                “know your client” (collected data will differ depending on the
                                client’s risk score). (KYC) 
                            </td>
                        </tr>
                        <tr>
                            <td>
                                9.Information obtained and/or created in order to fulfil the
                                requirements of applicable legislation 
                            </td>
                            <td>
                                Data that we are required to provide to public authorities, such as
                                tax administrators, courts, including data on income, payments and
                                other information held by us. 
                            </td>
                        </tr>
                        <tr>
                            <td>10.Contact details </td>
                            <td>
                                Phone number, e-mail, residential address, correspondence address
                                for delivery of Debit Card (if different from residential address).
                            </td>
                        </tr>
                        <tr>
                            <td>11.Communication details </td>
                            <td>
                                Date of the e-mail, letter, subject, the content of the
                                correspondence, messaging history, including, but not limited to,
                                claims and complaints made by you, our responses to you, names of
                                messages, the dates of messages.
                            </td>
                        </tr>
                        <tr>
                            <td>12.Information about your behaviour </td>
                            <td>
                                Your clicks, visited sections, interests, product or service
                                preferences, other information about your behaviour and your
                                activity on our website, Mobile App.
                            </td>
                        </tr>
                        <tr>
                            <td>13.Special category data </td>
                            <td>Biometric data. </td>
                        </tr>
                    </tbody>
                </table>
                <p class="title mt-40px">PURPOSES AND LEGAL BASIS FOR PERSONAL DATA PROCESSING </p>
                <table class="gridtable">
                    <thead>
                        <th>Purpose </th>
                        <th>Legal basis </th>
                        <th>Categories of personal data </th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                1.To register you on our Website or Mobile App/ to open your Account
                            </td>
                            <td>Your consent.</td>
                            <td>Contact details.</td>
                        </tr>
                        <tr>
                            <td>2.To download the Mobile App</td>
                            <td>Your consent.</td>
                            <td>Full name and email address.</td>
                        </tr>
                        <tr>
                            <td>3.To open your Account</td>
                            <td>
                                .Taking necessary steps before conclusion of the contract and/or
                                conclusion of the contract; 
                                <br />
                                .Legal obligations. 
                            </td>
                            <td>
                                .Basic personal data; 
                                <br />
                                .Identification and other background verification data; 
                                <br />
                                .Contact details;
                                <br />
                                .Other personal data needed (in order to evaluate the possibility of
                                providing Services).
                            </td>
                        </tr>
                        <tr>
                            <td>
                                4.To perform the contract concluded with you, including (but not
                                limited to) provision of the Services 
                            </td>
                            <td>
                                .Performance of the contract;
                                <br />
                                .Legal obligations. 
                            </td>
                            <td>
                                Basic personal data;
                                <br />
                                .Identification and other background verification data;
                                <br />
                                .Monetary operation details;
                                <br />
                                .Details of your activities in your website account;
                                <br />
                                .Details of your activities in our Mobile App; .Details of your
                                existing bank account/-s;
                                <br />
                                .Information related to legal requirements;
                                <br />
                                .Contact details;
                                <br />
                                .Communication details; Other personal data needed (in order to
                                evaluate the possibility of providing services).
                            </td>
                        </tr>
                        <tr>
                            <td>
                                5.To carry out ongoing Client Due Diligence (CDD) and manage ML/TF
                                risk, identify, investigate and report suspicious transactions and
                                potential market abuse 
                            </td>
                            <td>Legal obligations. </td>
                            <td>
                                .Basic personal data;
                                <br />
                                .Identification and other background verification data;
                                <br />
                                .Monetary operation details;
                                <br />
                                .Details of your existing bank account/-s;
                                <br />
                                .Information related to legal requirements;
                                <br />
                                .Contact details.
                                <br />
                                (Scope of the processing of personal data depends on the specific
                                operation being investigated) 
                            </td>
                        </tr>
                        <tr>
                            <td>
                                6.To enable us to comply with anti-money laundering and
                                anti-terrorist financing requirements and to enforce compliance with
                                the requirements relating to sanctions (including Know Your Customer
                                ("KYC")obligations, such as to determine the purpose of the business
                                relationship and whether you are a politically exposed person, as
                                well as the source of funds) 
                            </td>
                            <td>Legal obligations. </td>
                            <td>
                                .Basic personal data;
                                <br />
                                .Identification and other background verification data;
                                <br />
                                .Monetary operation details;
                                <br />
                                .Details of your existing bank account/-s;
                                <br />
                                .Information related to legal requirements;
                                <br />
                                .Contact details;
                                <br />
                                Other personal data needed.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                7.To comply with other legal requirements under applicable
                                legislation in areas such as the provision of payment services
                                (including client ongoing client due diligence (CDD), financial
                                markets and financial services, market abuse, personal data
                                protection, accounting and taxation 
                            </td>
                            <td>Legal obligations. </td>
                            <td>
                                .Basic personal data;
                                <br />
                                .Identification and other background verification data;
                                <br />
                                .Information obtained and/or created in order to fulfil the
                                requirements of applicable legislation;
                                <br />
                                .Contact details;
                                <br />
                                .Other personal data needed.
                                <br />
                                (the scope of processed personal data depends on the client's risk
                                category, specific situation and may include all of the above
                                categories of personal data or a part of this personal data) 
                            </td>
                        </tr>
                        <tr>
                            <td>8.To verify your identity </td>
                            <td>Your consent. </td>
                            <td>
                                .Special category data;
                                <br />
                                .Identification and other background verification data.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                9.For security purposes, to investigate possible fraud or other
                                violations of our Services
                            </td>
                            <td>
                                .Performance of the contract; 
                                <br />
                                .Legitimate interest; 
                                <br />
                                .Legal obligations. 
                            </td>
                            <td>
                                .Basic personal data;
                                <br />
                                .Identification and other background verification data;
                                <br />
                                .Monetary operation details;
                                <br />
                                .Details of your activities in your website account; .Details of
                                your activities in our Mobile App; .Details of your activities in
                                our website;
                                <br />
                                .Details of your existing bank account/-s;
                                <br />
                                .Information related to legal requirements;
                                <br />
                                .Contact details;
                                <br />
                                .Communication details;
                                <br />
                                .Other personal data needed (in order to evaluate the possibility of
                                providing services).
                            </td>
                        </tr>
                        <tr>
                            <td>
                                11.To conduct research and development of our Website, Mobile App
                                and Services to provide you and others with a better, more
                                intuitive, and personalized experience
                            </td>
                            <td>Legitimate interest. </td>
                            <td>
                                .Information about your behaviour;
                                <br />
                                .Communication details;
                                <br />
                                .Details of your activities in your website account; .Details of
                                your activities in our Mobile App;
                                <br />
                                .Details of your activities in our website;
                                <br />
                                .Contact details. 
                            </td>
                        </tr>
                        <tr>
                            <td>
                                12.To provide an answer when you contact us via our website or other
                                communication means 
                            </td>
                            <td>Your consent.</td>
                            <td>
                                .Basic personal data;
                                <br />
                                .Contact details;
                                <br />
                                .Communication details;
                                <br />
                                .Other personal data needed (in order to evaluate the possibility of
                                providing services).
                            </td>
                        </tr>
                        <tr>
                            <td>
                                13.To let you know about upcoming changes or improvements of
                                Services, Website and/or Mobile App, provide other important
                                information
                            </td>
                            <td>Your consent. </td>
                            <td>
                                .Basic personal data;
                                <br />
                                .Contact details;
                                <br />
                                .Communication details;
                                <br />
                                .Other personal data needed (in order to evaluate the possibility of
                                providing services).
                            </td>
                        </tr>
                        <tr>
                            <td>14.To carry out direct marketing</td>
                            <td>Your consent.</td>
                            <td>
                                .Basic personal data;
                                <br />
                                .Contact details.
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p>
                    3.1. Processing of client verification data
                    <br />
                    3.1.1. For the Account to be created you must verify your identity. We verify
                    you by the personal data you provide during registration. However, such personal
                    data must be confirmed, therefore in addition, for verification purposes we also
                    rely on verification services, managed, and provided to us by our service
                    providers.
                    <br />
                    3.1.2. While exercising this verification step, you will be requested to upload
                    your ID document. You will undergo facial verification. For the mentioned
                    purposes we receive and rely on a certain confirmation from our service
                    providers that your identity is verified. Please note, that under the applicable
                    laws SaintPay is obligated to collect and store all data received during client
                    identification and verification process therefore scanned copies of ID
                    documents, data related to facial recognition and other information will be
                    stored by SaintPay in accordance with this Privacy Policy and applicable
                    legislation.
                    <br />
                    3.1.3. SaintPay may request to provide further information (i.e., information on
                    participation in politics (through enhanced customer identification, a bank
                    statement)) that will allow SaintPay to reasonably identify you and verify your
                    identity. SaintPay reserves the right to contact you and request to provide more
                    information or approve that provided information is up-to-date and valid.
                    <br />
                    3.1.4. SaintPay processes the above-mentioned personal data used for client’s
                    verification to comply with regulatory and legal obligations as well as to
                    ensure that clients are not attempting to create additional Accounts or to
                    commit fraudulent actions. If you do not feel comfortable with this
                    identification method, you may contact us by e-mail at contact@saintpay.com for
                    an alternative way to identify you.  
                    <br />
                    3.1.5. Processing of your ID document, facial verification data, uploaded to a
                    third-party database as described above, is covered by third parties’ privacy
                    policies. All personal data you provide for the verification process shall be
                    provided directly by you to our service provider performing your verification
                    and therefore processing of such data shall be covered by the policies of such
                    service provider. You should carefully review privacy policies of such service
                    providers before starting the verification process.
                    <br />
                    <br />
                    3.2. Processing personal data of other individuals
                    <br />
                    3.2.1. In providing personal data of any individual other than yourself to us
                    during the use of our Services, you agree that you have obtained consent and
                    informed such individual about the disclosure of their personal data for
                    collection and use and brought this Privacy Policy to their attention. By
                    providing such personal data to us you bear all the responsibility towards such
                    individuals if you have not received proper consents for such provision and you
                    undertake to indemnify us for any liability which may appear due to unlawful
                    provision and/or disclosure of personal data.
                </p>
                <p class="title mt-40px">DEVELOPING THE WEBSITE AND MOBILE SERVICES</p>
                <p>
                    We use personal data to conduct research and development of our Website, Mobile
                    App and Services to provide you and others with a better, more intuitive, and
                    personalized experience, driving membership growth.
                    <br />
                    <br />
                    4.1. Client support
                    <br />
                    We use personal data to keep in touch with you to provide you with customer
                    service, notify you on news and updates, and provide you with security notices
                    or information.
                    <br />
                    <br />
                    4.2. Security and investigations
                    <br />
                    We use personal data for security, fraud prevention and investigations. We use
                    your personal data (including your communications) if we think it is necessary
                    for security purposes or to investigate possible fraud or other violations of
                    our Terms of Services, this Privacy Policy, implementing the regulatory and
                    legal obligations. We may ask you to provide any additional information which we
                    think may influence the process of investigation or examination of your
                    complaint / request.
                    <br />
                    <br />
                    4.3. Profiling
                    <br />
                    Profiling carried out by SaintPay involves processing of personal data by
                    automated means for the purposes of legislation relating to risk management and
                    continuous and periodic monitoring of transactions to prevent fraud. Such
                    ongoing profiling is based on legitimate interests of SaintPay, the performance
                    of a legal obligation and the execution of the agreement.
                    <br />
                    <br />
                    4.4. Providing information on similar products and services
                    <br />
                    4.4.1. When you sign up to SaintPay, we give you the opportunity to opt in to
                    offers and promotions. If you choose to opt in, we then use your data to tailor
                    offers to you, so they are more likely to interest you. You can opt out again at
                    any time by going to the settings in your Mobile App or browser. You can adjust
                    your preferences or tell us you do not want to hear from us, at any time. We
                    will not pass your details on to any outside organizations for their marketing
                    purposes without your permission.
                    <br />
                    4.4.2. When we use social media for marketing, your information may be shared
                    with social media platforms, who may use it to check if you also hold an account
                    with them. If you do, we may ask the advertising partner or social-media
                    provider to:
                    <br />
                    –use your information to send our adverts to you when we think you might like
                    one of our new products;
                    <br />
                    –not send you our adverts, because you already used the service advertised;
                    <br />
                    -advertise to people with a similar profile to you (e.g., if one of our services
                    might appeal to someone with interests like yours).
                    <br />
                    4.4.3. If you want us to stop sharing your personal information for marketing
                    purposes, just let us know via the SaintPay Website or Mobile App, or by
                    emailing us at
                    <a
                        href="mailto=legal@saintpay.com"
                        class="link text-base-16px text-dark underline"
                    >
                        legal@saintpay.com
                    </a>
                    . You can also manage your marketing preferences directly with the social media
                    platforms you are signed up to.
                    <br />
                    <br />
                    4.5. Third Party Information We will combine this information with information
                    we have collected about you and we will use this information to help us better
                    understand your financial circumstances and behaviour so that we may make
                    decisions about how we manage your Account and to decide about whether to agree
                    to approve application on Account opening.
                </p>
                <p class="title mt-40px">PERSONAL DATA RECEIVED FROM THIRD PARTIES</p>
                <p>
                    5.1. We collect and receive your personal data from yourself, as well as from
                    the following sources:
                    <br />
                    -We work closely with third parties to help us deliver our Service to you. These
                    third parties are business partners, sub-contractors in technical, payment and
                    delivery services, advertising networks, analytics providers, search information
                    providers, credit reference agencies, fraud prevention agencies, customer
                    service providers and developers. Information we may collect about you from such
                    parties can include credit search information, information which helps us to
                    verify your identity or information relating to your payment transactions;
                    <br />
                     We may receive your personal data from banks or other financial institutions
                    in case the personal data is received while executing payment operations;
                    <br />
                    – We may receive your personal data from other legal sources, such as public
                    registers, internet search engines, public sources such as social media.
                    <br />
                    5.2. If you are a beneficial owner, shareholder, representative or employee of
                    our corporate client we are collecting your personal data to fulfil legal and
                    regulatory obligations. Your personal data is provided to us by the
                    representatives of the company where you hold a certain position. Personal data
                    received under this clause is processed in accordance with the provisions of
                    this Privacy policy and you have all the rights of the data subject listed
                    herein in this Privacy Policy and in the applicable laws.
                </p>
                <p class="title mt-40px">HOW WE SHARE PERSONAL DATA</p>
                <p>
                    6.1. To provide you with the Services and meet our legal and regulatory
                    obligations, we use third parties’ services, and such third parties use personal
                    data in delivering their services to us. Therefore, we may share the information
                    we collect about you with our service providers (Data processors) such as:
                    <br />
                    - Cloud storage/servers’ providers. We use their service to store your data
                    safely and securely;
                    <br />
                     Card issuing institutions. For providing you with a card to use our Services;
                    <br />
                     Identification and verification services providers – to verify your identity;
                    <br />
                     Auditors, accountants, and lawyers - to complete financial, technical, and
                    legal audits of our operations, we may need to share information about your
                    Account as part of such an audit;
                    <br />
                     Public authorities, institutions, organisations, courts and other third
                    parties, but only upon request and only when required by applicable laws, or in
                    cases and under procedures provided for by applicable laws;  Other service
                    providers with which we have concluded service provision agreements or when such
                    sharing is mandatory according to applicable law.
                    <br />
                    6.2. International transfers
                    <br />
                    6.2.1. We only use the services of those data processors which ensure safeguards
                    and use technical and organizational security measures equivalent to the ones
                    required by GDPR.
                    <br />
                    6.2.2. The data that we collect from you will be transferred to, and stored at,
                    a destination inside the European Economic Area (EEA).
                    <br />
                    6.2.3. Personal data may be processed outside of the EEA for us to fulfil our
                    contractual obligations towards you to provide the Services. We will need to
                    process your personal data for us, for example, to action a request made by you
                    to execute an international payment, process your payment details, provide
                    global anti-money laundering, and counter terrorist financing solutions and
                    provide ongoing support services. We will take all steps to ensure that your
                    data is treated securely and in accordance with this Privacy Policy.
                    <br />
                    6.2.4. Transfers of personal data outside the EEA can be done in a number of
                    different ways, for example:
                    <br />
                    - the country to which we send the personal data, a territory or one or more
                    specified sectors within that third country, or the international organization
                    is approved by the European Commission as having an adequate level of
                    protection;
                    <br />
                    - the recipient has signed or contains in its terms of service (service
                    agreement) standard contractual clauses adopted by the European Commission;
                    <br />
                    - special permission has been obtained from a supervisory authority.
                    <br />
                    6.2.5. We may transfer personal data to a third country by taking other measures
                    if it ensures appropriate safeguards as indicated in the GDPR or on the basis of
                    derogations.
                    <br />
                    <br />
                    6.3. Our legal obligation to use or disclose personal data
                    <br />
                    As a regulated financial institution, we may need to share your personal data to
                    state and public authorities. We will only do so when we are legally required to
                    provide information or when we need to take legal action to defend our rights,
                    as well as the cases, where we have a belief in good faith that access, use,
                    preservation or disclosure of the information is reasonably necessary to meet
                    any applicable law, regulation, legal process or enforceable governmental
                    request, enforce applicable Terms of Services, including investigation of
                    potential violations, detect, prevent or otherwise address fraud, security or
                    technical issues.
                    <br />
                    <br />
                    6.4. Others
                    <br />
                    SaintPay may partner with other financial institutions, such as banking, credit,
                    and financial services partners, including banking partners, banking
                    intermediaries, credit companies and international payments services providers.
                    With their help we can provide you Services and to meet legal and regulatory
                    requirements we might be obligated to share your account details with such
                    partners to the extent you transact or interact with customers of such partners.
                </p>
                <p class="title mt-40px">YOUR RIGHTS</p>
                <p>
                    7.1. The right to be informed: this right enables you to be provided with clear,
                    transparent and easily understandable information about how we use your personal
                    data;
                    <br />
                    <br />
                    7.2. The right to request access to information we process about you: this right
                    enables you to receive a copy of the personal data we hold about you;
                    <br />
                    <br />
                    7.3. The right to request to correct incorrect / inaccurate information about
                    you: this right enables you to have any incomplete or inaccurate personal data
                    we hold about you to be corrected. Please note that we may need to verify the
                    accuracy of the new data you provide to us;
                    <br />
                    <br />
                    7.4. The right to request to transfer all or part of the personal data: this
                    right enables you to ask us to provide you with your personal data in a
                    structured, commonly used, machine-readable format, which you can then transfer
                    to another appropriate data controller. Note that this right only applies to
                    automated information which you initially provided for us to use and consented
                    for its use or where we used the information to perform a contract with you;
                    <br />
                    <br />
                    7.5. The right to request erasure of personal data: this right enables you to
                    ask us to delete or remove personal data where there is no good reason for us to
                    process it, or if you have successfully exercised your right to object to
                    processing (as described in clause 7.7. herein below). Please note that
                    SaintPay, as a regulated financial institution is obligated under the applicable
                    laws regarding prevention of money laundering and terrorist financing as well as
                    of Law on Electronic Money and Electronic Money Institutions of the Republic of
                    Italy to retain certain information you have provided for several years, as
                    indicated in certain legislation, therefore we may not always be able to comply
                    with your request of erasure for the mentioned reasons. We will notify you at
                    the time of your request if the situation is as described;
                    <br />
                    <br />
                    7.6. The right to request restriction of data processing: this right enables you
                    to ask us to suspend the processing of your personal data in the following
                    cases: (a) if you want us to establish the data’s accuracy; (b) where our use of
                    the data is unlawful but you do not want us to erase it; (c) where you need us
                    to hold the data even if we no longer require it as you need it to establish,
                    exercise or defend legal claims; (d) you have objected to our use of your data
                    but we need to verify whether we have overriding legitimate grounds to use it.
                    Please note that such requests may lead to a situation that we may not be able
                    to perform our contractual obligations towards you or enter a contract with you.
                    If this would be the case, we will notify you about it;
                    <br />
                    <br />
                    7.7. The right to object to processing of personal data when processing is
                    carried out based on legitimate interest: this right can be exercised in a
                    situation where we are relying on our legitimate interest (or those of a third
                    party) but in your situation such processing impacts on your fundamental rights
                    and freedoms. You also have the right to object where we are processing your
                    personal data for direct marketing purposes. Please note that SaintPay, as a
                    regulated financial institution is obligated under the applicable laws regarding
                    prevention of money laundering and terrorist financing in the Republic of Italy
                    to process your certain personal data for compliance purposes, therefore in some
                    cases, we may demonstrate that we have compelling legitimate grounds to process
                    your personal data which override your rights. Please note that requirements of
                    the mentioned laws supersede any right to objection under applicable data
                    protection laws. If you object to the processing of certain data, then we may
                    not be able to provide you Services and it is likely we will have to terminate
                    your account;
                    <br />
                    <br />
                    7.8. The right to withdraw permission: this right enables you to withdraw your
                    consent at any time, if you have given us consent. It will have been lawful for
                    us to use the personal data up to the point you withdrew your permission;
                    <br />
                    <br />
                    7.9. The rights related to automated decision-making: these rights enable you
                    not to be subject to a decision which is based solely on automated processing
                    and which produces legal or other significant effects. In particular, you have
                    the right to obtain human intervention, to express point of view, to obtain an
                    explanation of the decision reached after an assessment and to challenge such a
                    decision.
                    <br />
                    <br />
                    7.10. To exercise any of the rights mentioned above, please reach out to our
                    client support team via email contact@saintpay.com by filling out a request form
                    on our Website. We may ask you to verify your identity and for more information
                    regarding your request.
                    <br />
                    <br />
                    7.11. You may at any time edit, update, or delete your contact details
                    contacting our service center via e-mail contact@saintpay.com.
                </p>
                <p class="title mt-40px">HOW LONG DO WE KEEP YOUR DATA</p>
                <p>
                    8.1. We will keep your personal data for as long as it is needed for the
                    purposes for which your data was collected and processed, including for the
                    purposes to comply with any legal, regulatory, tax, accounting or reporting
                    obligations. This means that we store your data for as long as it is necessary
                    for provision of the Services and as required by the retention requirements in
                    laws and regulations. If the legislation of the Republic of Italy does not
                    provide any applicable data retention period, it shall be determined by us,
                    taking into account the legitimate purpose of the data retention, the legal
                    basis and the principles of lawful processing of personal data.
                    <br />
                    <br />
                    8.2. SaintPay, as a regulated financial institution is obligated under the
                    applicable laws regarding prevention of money laundering and terrorist financing
                    to retain your personal data for the following periods:
                    <br />
                    <br />
                    - Client identification data and verification data – eight years after
                    termination of the contract relations in accordance with the Law of the Republic
                    of Italy on the Prevention of Money Laundering and Terrorist Financing;
                    <br />
                    - History of transactions – five years after terminations of the contract
                    relations in accordance with the Law of the Republic of Italy;
                    <br />
                    - in case your application is rejected, your personal data shall be stored for a
                    period of 3 months, except when such data was collected for the implementation
                    of the obligations under the applicable anti money laundering laws in the
                    Republic of Italy.
                    <br />
                    <br />
                    8.3. We therefore use this retention requirement as a benchmark for all personal
                    data that we receive from you. To not hold your information for longer than is
                    strictly necessary we will not hold any of your personal data for more than 8
                    years after the termination of our business relationship.
                    <br />
                    <br />
                    8.4. Other personal data retention periods are as follows:
                    <br />
                    -as long as your consent remains in force, if there are no other legal
                    requirements which shall be fulfilled with regard to the personal data
                    processing;
                    <br />
                    -the personal data submitted by you through our Website, Mobile App or via
                    e-mail is kept for an extent necessary for the fulfilment of your request and to
                    maintain further cooperation, but no longer than 6 months after the last day of
                    the communication, if there are no legal requirements to keep them longer. 
                </p>
                <p class="title mt-40px">COMPLAINTS</p>
                <p>
                    9.1. You have the right to lodge a complaint to the national Data Protection
                    Agency (DPA) in the country of residence in the event where your rights may have
                    been infringed. We would, however, appreciate the chance to deal with your
                    concerns before you approach the DPA and find a solution at your satisfaction.
                    So please contact us in the first instance.
                </p>
                <p>
                    9.2. If you are a resident of the Republic of Italy, you can contact the Garante
                    Per La Prtezione Dei Dati Personali (i.e. the Italian Data Protection Authority)
                    if you believe that the personal data is processed in a way that violates your
                    rights and legitimate interests stipulated by applicable legislation. You may
                    apply in accordance with the procedures for handling complaints that are
                    established by the Garante Per La Prtezione Dei Dati Personali and which may be
                    found by this link: https://www.garanteprivacy.it/web/garante-privacy-en
                </p>
                <p>
                    9.3. Please be noticed that SaintPay identifies you by personal data and e-mail,
                    which you have provided to us when you signed up for the Services. When you
                    submit your request, always provide your personal details and send your request
                    via email you have submitted when you signed up for the Services. In other
                    cases, we will not be able to identify you properly and submit the information
                    requested by you or to fulfil your request.
                </p>
                <p class="title mt-40px">DATA PROTECTION OFFICER (DPO)</p>
                <p>
                    10.1. Contact email:
                    <a
                        href="mailto=legal@saintpay.com"
                        class="link text-base-16px text-dark underline"
                    >
                        legal@saintpay.com
                    </a>
                    who is responsible for matters relating to privacy and data protection.
                </p>
                <p>
                    10.2. If you have any further questions regarding the personal data SaintPay
                    collects, or how we use it, then please feel free to contact us at the details
                    as indicated above hereof.
                </p>
            </div>
        </div>
        <footer-bar :footer="footerList" />

        <!-- jump to the top -->
        <button
            v-if="showScrollBtn"
            @click="goTop"
            class="fixed bottom-24px right-24px bg-dark w-40px h-40px rounded-10px flex items-center justify-center hover:(cursor-pointer bg-primary) active:bg-secondary"
        >
            <img :src="require('../../img/chever.svg')" />
        </button>
    </div>
</template>
<script>
import NavBar from '@/components/NavBar/index.vue'
import { staticData } from '../data.js'

export default {
    name: 'Privacy',
    components: {
        NavBar,
        footerBar: () => import('./Footer/index.vue')
    },
    mounted() {
        window.addEventListener('scroll', this.scrollPage)
        this.goTop()
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.scrollPage)
    },
    data() {
        return {
            ...staticData,
            showScrollBtn: false
        }
    },
    methods: {
        scrollPage() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                this.showScrollBtn = true
            } else {
                this.showScrollBtn = false
            }
        },
        // to scroll up to the page
        goTop() {
            document.body.scrollTop = 0 // For Safari
            document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
        }
    }
}
</script>
<style scoped>
table.gridtable {
    font-family: var(--font-family-SF_Pro);
    font-size: 14px;
    color: var(--dark);
    border-width: 1px;
    border-color: var(--lineSecondary);
    border-collapse: collapse;
    margin-top: 20px;
    margin-bottom: 20px;
}
table.gridtable th {
    font-size: var(--font-size-s);
    font-weight: 510;
    border-width: 1px;
    padding: 16px;
    border-style: solid;
    border-color: var(--lineSecondary);
    background-color: var(--grey);
}
table.gridtable td {
    border-width: 1px;
    padding: 16px;
    border-style: solid;
    border-color: var(--lineSecondary);
    background-color: var(--light);
}
</style>
