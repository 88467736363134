<template>
    <div class="page text-dark bg-light">
        <NavBar :navigation="navList" @nav="goTop" />
        <div
            class="w-full px-120px pt-48px mt-72px pb-92px ipad:(flex flex-col px-24px) mobile:(w-full flex flex-col py-32px px-16px mt-72px)"
        >
            <div class="sf_18 flex gap-16px h-24px items-center text-base-16px tracking-0.003em">
                <span class="font-510">Terms &amp; Conditions</span>
                <span class="bg-lineSecondary w-2px h-24px"></span>
                <router-link to="/Privacy" class="link text-base-16px text-neutral font-510">
                    Privacy and Policy
                </router-link>
            </div>
            <div
                class="section_headline text-dark mt-40px ipad:(flex justify-center) mobile:(leading-48px text-base-40px text-center flex justify-center)"
            >
                <span class="ligne">Terms and Conditions</span>
            </div>
            <div class="max-w-384px mobile:(max-w-full)"></div>
            <div
                class="sf_18 text-base-16px tracking-0.003em font-510 leading-24px flex gap-24px mt-20px mobile:(flex-col w-full)"
            >
                <div class="max-w-384px mobile:(max-w-full)">
                    Table of Contents
                    <br />
                    1. Key Definitions
                    <br />
                    2. Access to Company Services
                    <br />
                    2.1. Registration
                    <br />
                    2.2. Your information
                    <br />
                    2.3. Eligibility
                    <br />
                    2.4. Identity Verification
                    <br />
                    2.5.&nbsp;&nbsp;Information and documents collected
                    <br />
                    2.6. Usage Requirements
                    <br />
                    2.7. Prohibition of Use
                    <br />
                    3. SaintPay Account
                    <br />
                    4. Company Services
                    <br />
                    5. General Obligations
                    <br />
                    5.1. Application of Section 5.
                    <br />
                    5.2. Conditions and Restrictions.
                    <br />
                    5.3. Accuracy of Information.
                    <br />
                    5.4 Cancellations
                    <br />
                    5.5. Insufficient Funds.
                    <br />
                    5.6. Taxes.
                    <br />
                    5.7. Compliance with all applicable laws and regulations.
                    <br />
                    6. Account Balance(s)
                    <br />
                    6.1. Payment for orders.
                    <br />
                    6.2. Sale of Digital Tokens from a Digital Token Account.
                    <br />
                    6.3. Transfer Authorization.
                    <br />
                    6.4. Rejected Transactions.
                    <br />
                    6.5. Ownership of Digital Tokens.
                    <br />
                    6.6 Ownership of Funds.
                    <br />
                    7. Orders
                    <br />
                    7.1. Application of this Section
                    <br />
                    7.2. Purchase Order.
                    <br />
                    7.3. Fiat Withdrawal
                    <br />
                    7.4. Purchase Order
                    <br />
                    7.5. Sale Orders
                    <br />
                    7.6. Market rates
                    <br />
                    7.7. Market Volatility
                    <br />
                    7.8. Order Fulfilment
                    <br />
                    7.9 Error Correction Attempts
                    <br />
                    7.10 Independent Relationship, No Advice.
                    <br />
                    7.11 No Custody or Possession
                    <br />
                    7.12 No Refunds
                    <br />
                    8. Risk Disclosure
                    <br />
                    9. Fees
                    <br />
                    10. Electronic Notices
                    <br />
                    11. Disputed Property
                    <br />
                    12. Acceptable Use
                    <br />
                    13. Feedback
                    <br />
                    14. Copyrights and Other Intellectual Property Rights
                    <br />
                    15. Company Partners
                    <br />
                    16. Suspension; Termination
                    <br />
                    17. Discontinuance of Services
                    <br />
                    18. Disclaimer of Warranties
                    <br />
                    19. Limitation of Liability
                    <br />
                    20. Indemnity
                    <br />
                    21. Applicable Law and Disputes Resolution
                    <br />
                    22. Miscellaneous
                    <br />
                    22.1. Entire Agreement; Order of Precedence.
                    <br />
                    22.2. Third Party Rights
                    <br />
                    22.3. Amendment
                    <br />
                    22.4. Waiver
                    <br />
                    22.5. Severability
                    <br />
                    22.6. Force Majeure Events
                    <br />
                    22.7. Assignment
                    <br />
                    22.8. Headings
                    <br />
                    22.9. Governing language and translations
                    <br />
                    22.10. Survival
                    <br />
                    22.11. Privacy Policy
                    <br />
                    ANNEX 1
                    <br />
                    ANNEX 2
                </div>
                <div class="w-792px mobile:(w-full)">
                    <p>
                        Please read these Terms of Use and the Risk Disclosure Statement carefully.
                        By accessing or using any service made available by us (the “Company
                        Services”), you agree to be legally bound by these Terms of Use (including
                        the Risk Disclosure Statement), the Privacy Policy and all terms
                        incorporated by reference in the foregoing.
                        <br />
                        These Terms of Use are entered into between SaintPay S.R.L(“Company”) and
                        you. By accessing, downloading, using or clicking on “I agree”, “I accept”,
                        “Start” or any similar agreement, acceptance or commencement acknowledgment
                        to accept any Company Services provided by the Company, you agree that you
                        have read, understood and accepted all of the terms and conditions
                        stipulated in these Terms the Privacy Policy and such other terms and
                        conditions, user agreements, etc. that may be presented to you during your
                        use of the Company Services
                        <br />
                        In addition, when using some features of the Services, you may be subject to
                        specific additional requests for information by third-party service
                        providers with whom we have partenred to provide you the Services (such
                        third-party, a "Company Partner"). Additional requests for information
                        include (but shall not be limited to), information pertaining to, the
                        identification and authentication of your identity, validating your
                        source(s) of income and/or wealth, confirming your ownership of your phone
                        number or payment instruments, or verifying your information against third
                        party databases or through other sources ("Information Requests"). In
                        addition to the foregoing, you may be subject to additional rules (e.g.
                        transaction limits) depending on the specific service you have elected to
                        utilise ("Additional Rules). In this regard, by utilising the Services, you
                        hereby agree to cooperate with all Information Requests and Additional
                        Rules. Further, you acknowledge that not all Services are provided by the
                        Company and accordingly, the Company shall not be liable for any losses,
                        damage or otherwise, from your use of such Services, or such other losses,
                        damages or otherwise, which may result from the actions and/or inactions of
                        our Company Partners.
                        <br />
                        By accessing, using or attempting to use the Services in any capacity, you
                        acknowledge that you accept and agree to be bound by these Terms of Use. If
                        you do not agree, do not utilize the Services in any way.
                        <br />
                        These Terms of Use and any terms expressly incorporated herein (“Terms”)
                        apply to your use of the website operated and maintained by SaintPay S.R.L
                        (“Company”, “we”, or “us”), and the Services as described in these Terms.
                        The pronouns “you” or “your” used in the Terms and Conditions must be
                        understood as you, the customer of SaintPay S.R.L.
                    </p>
                    <br />
                    <p class="title">1. Key Definitions</p>
                    <p>
                        Capitalized terms not otherwise defined in these Terms will have the
                        following meaning:
                        <br />
                        The Company”, “us”, “we” means SaintPay S.R.L, legal entity’s reg. no.
                        12902640865, a company registered under the laws of the Republic of Italy.
                        <br />
                        “External Account” means any bank account or similar accounts that are not
                        issued or maintained by the Company (and to the extent applicable, Company
                        Partners) from which you may transfer or receive Funds, as approved by The
                        Company or Company Partners for the purpose of utilising the Services, and
                        shall where the context applies, include any debit card or credit card
                        issued under such account, or where such account is utilised by you as
                        settlement for any credit card.
                        <br />
                        “Funds” means Fiat Currency.
                        <br />
                        “Fiat Currency” means any national currency.
                        <br />
                        “SaintPay Account” means a user account opened by the Company, through which
                        users may access the Services but does not include any External Account,
                        bank account or such other supplementary accounts that a user may require
                        for the successful utilisation of the Services.
                        <br />
                        “Digital Tokens” means any encrypted digital tokens or cryptocurrencies
                        which are based on blockchain/cryptography technology, their derivatives or
                        such other digital assets.
                        <br />
                        “Digital Token Account” means any Digital Token address or account hosted by
                        third parties and owned or operated by you, which shall be approved by us
                        for the purposes of the Services
                    </p>
                    <br />
                    <p class="title">2. Access to Company Services</p>
                    <p>
                        2.1. Registration
                        <br />
                        All users must have a registered and approved SaintPay Account prior to
                        using the Services. Prior to a MultiExchange Account being registered, you
                        must accept these Terms, the Privacy Policy, and other Company website
                        rules.
                        <br />
                        <br />
                        2.2. Your information
                        <br />
                        In order to receive the Services, you must provide any information requested
                        by the Company. In this regard, you agree to:
                        <br />
                        a)provide accurate and truthful information;
                        <br />
                        b)maintain and promptly update your information;
                        <br />
                        c)take responsibility for all activities that occur through your use of the
                        Services and accept all risks of such usage to the maximum extent permitted
                        by law.
                        <br />
                        <br />
                        2.3. Eligibility
                        <br />
                        2.3.1. By utilising the Services, you represent and warrant that:
                        <br />
                        a)as an individual, you are at least 18 or are of legal age to form a
                        binding contract under applicable laws;
                        <br />
                        b)as an individual, legal person, or other organization, you have full legal
                        capacity and sufficient authorizations to enter into these Terms;
                        <br />
                        c)you have not been previously suspended or removed from using the Services;
                        <br />
                        d)you do not have a pre-existing SaintPay Account
                        <br />
                        e)if you act as an employee or agent of a legal entity, and enter into these
                        Terms on their behalf, you represent and warrant that you have all the
                        necessary rights and authorizations to bind such legal entity;
                        <br />
                        f)your use of the Services will not violate any and all laws and regulations
                        applicable to you, including but not limited to regulations on anti-money
                        laundering, anti-corruption, and counter-terrorist financing.
                        <br />
                        g)you are eligible to use the services in accordance to these Terms.
                        <br />
                        2.3.2. The Company may refuse, in its discretion, to register a SaintPay
                        Account for and/or provide the Services to you.
                        <br />
                        <br />
                        2.4. Identity Verification
                        <br />
                        2.4.1. You will need to provide required personal information and to the
                        extent applicable, such other information per our Information Requests, for
                        identity verification. Such information will be used by the Company to
                        verify your identity. In order to ensure that the Company satisfies
                        applicable anti-money laundering and prevention of terrorism legislation,
                        provided information will be used by the Company to identify traces of money
                        laundering, terrorist financing, fraud and other financial crimes through
                        the Company, or for other lawful purposes stated by the Company.
                        <br />
                        2.4.2. The information we require to verify your identity may include, but
                        is not limited to, your name, email address, contact information, phone
                        number, username, government-issued ID, date of birth, and other information
                        collected during account registration.
                        <br />
                        We will collect, use and share such information in accordance with our
                        <router-link
                            to="/privacy"
                            class="link text-bas-16px font-400 underline text-dark"
                        >
                            Privacy Policy.
                        </router-link>
                        <br />
                        <br />
                        2.4.4. In addition to providing information specified in Sections 2.4.2. and
                        2.5., you agree to allow us to keep a record of that information during the
                        period for which your account is active and within eight (8) years after
                        your account is closed. You also authorize us to conduct necessary
                        investigations directly or through a third party to verify your identity or
                        protect you and/or us from financial crimes, such as fraud. You also
                        acknowledge and agree that your personal information may be disclosed to
                        credit bureaus and agencies for fraud prevention or financial crime
                        prevention, which may respond to our investigations in full.
                        <br />
                        2.4.5. After registration, you must ensure that the information is true,
                        complete, and timely updated when changed. If there are any grounds for
                        believing that any of the information you provided is incorrect, false,
                        outdated or incomplete, the Company reserves the right to send you a notice
                        to demand correction, directly delete the relevant information, and, as the
                        case may be, suspend or terminate all or part of the Services.
                        <br />
                        2.4.6. You agree and undertake to provide information specified in Sections
                        2.4.2. and 2.5 non only to The Company, but also (where applicable) to
                        Company Partners.
                    </p>
                    <br />
                    <p>2.5. Information and documents collected</p>
                    <p>
                        To ensure that Company satisfies applicable anti-money laundering and
                        prevention of terrorism legislation, you at any time could be asked to
                        provide the following. Please note that the list is not exhaustive and we
                        can request in our sole and absolute discretion, any of the following
                        additional documents:
                    </p>
                    <p>
                        a)information on the transaction where the initiator is a natural person-
                        name(s); the unique transaction code; the identification codes of the
                        payment account or of the deposited virtual currency or token wallets; the
                        identity document and its number; the personal identification number, the
                        date of birth or any other unique sequence of characters assigned to the
                        person to identify him/her and the nationality; the address of the place of
                        residence.
                    </p>
                    <p>
                        b)information on the transaction where the initiator is a legal person -
                        name; a unique transaction code; the identification codes of the payment
                        account or of the virtual currency or token wallets deposited; the code, or,
                        if no such code has been assigned, the registration statement; the address
                        of the registered office;
                    </p>
                    <p>
                        c)information on the natural person who is the payee of the transaction -
                        the name(s) of the payee, the surname(s), the personal identification
                        number, the date of birth or any other unique sequence of characters
                        assigned to the person to identify him/her and the nationality; the
                        identification codes of the payee's payment account or the wallets of the
                        deposited virtual currencies or tokens;
                    </p>
                    <p>
                        d)information on the legal person who is the payee of the transaction: name,
                        code (if any); the identification codes of the payee's payment account or
                        virtual currency or token wallet.
                    </p>
                    <br />
                    <p>2.6. Usage Requirements</p>
                    <p>
                        2.6.1. The SaintPay Account can only be used by the registered User. The
                        Company reserves the right to suspend, freeze or cancel the use of SaintPay
                        Accounts by persons other than the registered User. If you suspect or become
                        aware of any unauthorized use of your SaintPay Account, you should notify
                        the Company immediately.
                    </p>
                    <p>
                        2.6.2. The Company assumes no liability for any loss or damage arising from
                        the use of a SaintPay Account by you or any third party with or without your
                        authorization.
                    </p>
                    <p>
                        2.6.3. You agree to treat your access credentials (such as username and
                        password) which are linked to the SaintPay Account as confidential
                        information, and not to disclose such information to any third party. You
                        also agree to be solely responsible for taking the necessary security
                        measures to protect your SaintPay Account and personal information.
                    </p>
                    <p>
                        2.6.4. You will be solely responsible for keeping safe of your SaintPay
                        Account and the password linked to the same, and be responsible for all the
                        transactions under your SaintPay Account. The Company assumes no liability
                        for any loss or consequences caused by authorized or unauthorized use of
                        your account credentials, including but not limited to information
                        disclosure, information release, consent or submission of various rules and
                        agreements by clicking on the website, online agreement renewal, etc.
                    </p>
                    <p>2.6.5. You hereby agree that:</p>
                    <p>
                        a) you will notify the Company immediately if you are aware of any
                        unauthorized use of your MultiExchange Account and password or any other
                        violation of security rules;
                    </p>
                    <p>
                        b) you will strictly abide by all mechanisms or procedures of Company
                        regarding security, authentication, trading, charging, and withdrawal; and
                    </p>
                    <p>
                        c) you will take appropriate steps to logout from your SaintPay Account at
                        the end of each visit.
                    </p>
                    <p>
                        2.6.6. Further, pursuant to the AML/CFT Policy, The Company may, in its
                        discretion, carry out continuous monitoring of all SaintPay Accounts (and
                        where applicable, Digital Token Accounts held with Company Partner
                        Platforms, in conjunction with Company Partners). If any unusually large or
                        unusual patterns of orders or any inexplicable or suspicious circumstances
                        are observed, The Company may, in its discretion, place an administrative
                        hold on or freeze your SaintPay Account. The Company may also instruct
                        Company Partners or such other service providers with whom you have a
                        Digital Token Account or External Account, to hold or freeze such account,
                        until such time The Company is satisfied that you have not committed any
                        breach. You agree that The Company will have no liability or responsibility
                        for any permanent or temporary inability to access or use any Company
                        Services, SaintPay Account, External Account and/or Digital Token Account
                        (as applicable).
                    </p>
                    <br />
                    <p>2.7. Prohibition of Use</p>
                    <p>
                        The Company may not make the Services available in all markets and
                        jurisdictions, and may restrict or prohibit use of the Services from certain
                        jurisdictions (“Restricted Jurisdictions”). The list of Restricted
                        Jurisdictions is contained in Annex 1 (Restricted Jurisdictions) which may
                        be updated by the Company from time to time.
                    </p>
                    <p>2.7.1. By accessing and using Services, you represent and warrant:</p>
                    <p>
                        a) that you have not been included in any trade embargoes or economic
                        sanctions list (such as the United Nations Security Council sanctions list,
                        European Union sanctions list), the list of specially designated nationals
                        maintained by OFAC, or the denied persons or entity list of the US
                        department of commerce, etc.;
                    </p>
                    <p>
                        b) that you are not located in, under the control of, or a national or
                        resident of any Restricted Jurisdiction, or any country to which the United
                        States has embargoed goods or services;
                    </p>
                    <p>
                        c) that you are not identified as a "Specially Designated National" in USA;
                    </p>
                    <p>
                        d) that are not placed on the US Commerce Department’s Denied Persons List;
                    </p>
                    <p>
                        e) that you are not a citizen or resident of a sanctioned country according
                        to the up-to-date lists of the US Office of Foreign Assets Control (OFAC),
                        the United Nations, the European Union, and any EU Member State, the UK
                        Treasury;
                    </p>
                    <p>
                        f) that you meet all eligibility requirements for the Services at the time
                        of using any Services.
                    </p>
                    <p>
                        2.7.2. Additionally, by agreeing to these Terms you certify that you are
                        familiar with digital tokens or cryptographic tokens and trading in them and
                        the risks attached to them and their trading, including but not limited to
                        the risks highlighted in the Risk Disclosure Statement (Annex 2), and have
                        sought relevant professional and legal advice pertaining to the same.
                    </p>
                    <br />
                    <p class="title">3. SaintPay Account</p>
                    <p>
                        3.1. Number of SaintPay Accounts. Unless otherwise approved by the Company,
                        you shall only be entitled to register and operate one SaintPay Account
                        under your name (per your national ID, passport or such other document). The
                        Company reserves the right to close and cancel any additional SaintPay
                        Account(s) should we find that you have registered more than one SaintPay
                        Account. In such case, you agree that the Company shall not be liable for
                        any loss or damage incurred by you as a result of the Company’s decision to
                        close any of the aforementioned additional SaintPay Account(s).
                    </p>
                    <br />
                    <p>
                        3.2. SaintPay Account information and security. In order to utilise
                        Services, you must have a SaintPay Account and provide any requested
                        information for us to provide the Services (information may include, amongst
                        others, details of your Digital Token Account, External Account etc.). For
                        the purpose of your SaintPay Account, you agree to: (a) create a strong
                        password that you do not use for any other website or online service; (b)
                        provide accurate, complete and truthful information; (c) maintain and
                        promptly update the Company of any change of your information; (d) maintain
                        the security of your SaintPay Account by protecting your password and
                        restricting access to your SaintPay Account and such other accounts that may
                        be linked to the SaintPay Account; (e) promptly notify us if you discover or
                        otherwise suspect any security breaches related to your SaintPay Account;
                        and (f) take responsibility for all activities that occur under your
                        SaintPay Account and accept all risks of any authorized or unauthorized
                        access to your SaintPay Account, to the maximum extent permitted by law.
                    </p>
                    <br />
                    <p>
                        3.3. You are solely responsible for doing all things and taking all actions
                        necessary to monitor and secure your SaintPay Account (and to the extent
                        applicable, all such accounts linked to the SaintPay Account, including
                        External Accounts and Digital Token Accounts).
                    </p>
                    <br />
                    <p class="title">4. Company Services</p>
                    <p>
                        4.1. The Company provides you with a simple and convenient way to purchase
                        Digital Tokens using fiat currency (viz. credit card, debit card, bank
                        transfer), which shall be credited to the respective platforms operated by
                        our Company Partners (each such platform, a “Company Partner Platform”).
                    </p>
                    <br />
                    <p>
                        4.2. The Company may also assist you in sourcing liquidity from our Company
                        Partners or purchase from you, Digital Tokens (held on a Company Partner
                        Platform) which you wish to sell, in exchange for fiat currency.
                    </p>
                    <br />
                    <p class="title">5. General Obligations</p>
                    <p>5.1. Application of Section 5.</p>
                    <p>
                        Section 5 applies to all purchases and/or sale of Digital Tokens per the
                        Company Services viz. our Company Partner Platforms and/or your Digital
                        Token Accounts.
                    </p>
                    <br />
                    <p>5.2. Conditions and Restrictions.</p>
                    <p>
                        We may, at any time and in our sole discretion, make additional Information
                        Requests, refuse any order submitted via your SaintPay Account viz. our
                        Services, impose Additional Rules (such as limits on the purchase amount
                        permitted via the Services) or impose any other conditions or restrictions
                        upon your use of the Services without prior notice, or if we are instructed
                        to impose the same via our Company Partners. Further, we may restrict
                        Services for certain locations. In relation to the sale of Digital Tokens
                        viz. Company Partner Platforms and/or Digital Token Accounts, we will take
                        instructions from such Company Partners or Digital Token Account operators
                        as to any limitations that may be imposed on your Digital Token Account.
                    </p>
                    <br />
                    <p>5.3. Accuracy of Information.</p>
                    <p>
                        You must provide any information required when prompted by the Company or
                        Company Partners. We reserve the right to take instructions from our Company
                        Partners or External Account service providers and impose necessary account
                        controls or limitations should we be informed that you have not provided
                        accurate information to us, our Company Partners or such other third party
                        service providers. You represent and warrant that any information you
                        provide for the utilisation of the Services is accurate and complete.
                    </p>
                    <br />
                    <p>5.4. Cancellations.</p>
                    <p>
                        5.4.1. You may only cancel an order initiated via the Services if such
                        cancellation occurs before the Company executes the transaction (or for the
                        purposes of the sale of Digital Tokens, before the Company receives
                        instruction of an order from our Company Partners that you wish to sell to
                        us, the specific number of Digital Tokens, and the fulfilment of such other
                        conditions our Partner Platforms may require for the completion of a sale of
                        Digital Tokens). Once your order has been executed, you may not change,
                        withdraw or cancel your authorization for the Company to complete such
                        transaction. If an order has been partially filled, you may cancel the
                        unfilled remainder.
                    </p>
                    <p>
                        5.4.2. We may cancel your order in the following circumstances: (i) you
                        have, in our view, engaged in market manipulation or abuse (as described in
                        Section 12.1 below); (ii) your order under the circumstances involves an
                        obvious error with respect to price, quantity or other parameters; (iii)
                        your order would result in self-execution as described in Section 7.2
                        below;(iv) if required by any applicable law or regulation; (v) if required
                        for technical reasons, such as system maintenance and/or upgrade; and/or
                        (vi) we receive instructions from our Company Partners or External Account
                        service providers as to any of the foregoing.
                    </p>
                    <p>
                        5.4.3. Subject to Section 7.7 below, all orders are irreversible once
                        initiated. While we may, at our sole discretion, reverse a transaction in
                        the case of Manifest Error or if required by any applicable law or
                        regulation or under certain extraordinary conditions, a customer does not
                        have a right to a reversal of an order.
                    </p>
                    <br />
                    <p>5.5. Insufficient Funds.</p>
                    <p>
                        If you have an insufficient amount of Funds in your External Account for us
                        to complete an order via the Services, we may cancel the entire order or may
                        fulfill a partial order using the amount of Funds made available to us, less
                        any fees owed to the Company in connection with our execution of the order.
                        The aforementioned shall apply to a sale of Digital Tokens where we are
                        instructed by our Company Partners that you do not possess sufficient
                        Digital Tokens in your Digital Token Account (as described in Section 9
                        below).
                    </p>
                    <br />
                    <p>5.6. Taxes.</p>
                    <p>
                        It is your responsibility to determine what, if any, taxes apply to the
                        transactions you complete via the Company Services, and it is your
                        responsibility to report and remit the correct tax to the appropriate tax
                        authority. You agree that The Company and Company Partners are not
                        responsible for determining whether taxes apply to your transactions or for
                        collecting, reporting, withholding or remitting any taxes arising from any
                        transaction.
                    </p>
                    <br />
                    <p>5.7. Compliance with all applicable laws and regulations.</p>
                    <p>
                        You represent, warrant and undertake that you have and shall at all times
                        comply with all applicable laws and regulations in all jurisdictions
                        relevant to any Service provided or made available by us or Company Partners
                        (in the course of the Services) to you.
                    </p>
                    <br />
                    <p class="title">6. Account Balance(s)</p>
                    <p>6.1. Payment for orders</p>
                    <p>
                        6.1.1.In order to place or match an order via the Services (as described in
                        Section 7), you must first transfer Funds in accordance with the payment
                        details provided to you when an order is submitted via bank transfer, debit
                        card or credit card.
                    </p>
                    <p>
                        6.1.2. You may be required to verify that you control the External Account
                        which you elect to use to purchase Digital Tokens. You may be charged fees
                        by our Company Partners when you transfer Funds for the purchase of Digital
                        Tokens. The Company is not responsible for any additional fees levied by
                        Company Partners or External Account service providers or for the management
                        and security of any personal or payment details which you have provided to
                        us to the maximum extent permissible by applicable laws.
                    </p>
                    <br />
                    <p>6.2. Sale of Digital Tokens from a Digital Token Account.</p>
                    <p>
                        Provided that the balance of your Digital Tokens in your Digital Token
                        Account is greater than any minimum balance requirements needed to satisfy
                        all of your open orders and any fees payable to The Company (and where
                        applicable, Company Partners) and subject to your compliance with all
                        identity verification and other screening procedures and your satisfaction
                        of all requirements under The Company’s AML/CFT Policy as provided in
                        Section 2 above, The Company shall transfer any amount of Funds which you
                        have received in lieu of the sale of Digital Tokens less any fees charged
                        for such transfer, to your External Account.
                    </p>
                    <br />
                    <p>6.3 Transfer Authorization</p>
                    <p>
                        When you request that we transfer Funds for the purchase of Digital Tokens
                        viz. our Company Partners, such request shall be deemed authorisation of the
                        Company to execute such transaction via the Company Services.
                    </p>
                    <br />
                    <p>6.4. Rejected Transactions.</p>
                    <p>
                        In some cases, our Company Partners may reject your request for the sale
                        and/or purchase of Digital Tokens. You agree that you will not hold the
                        Company liable for any loss resulting from such rejected transactions.
                    </p>
                    <br />
                    <p>6.5. Ownership of Digital Tokens.</p>
                    <p>
                        You hereby represent and warrant that any Digital Tokens held by you, or
                        otherwise used by you in connection with the Company Services are owned by
                        you legally and beneficially, have not been derived from any illicit
                        activities and that all orders and transactions initiated are for your own
                        account and not on behalf of any other person or entity.
                    </p>
                    <br />
                    <p>6.6 Ownership of Funds.</p>
                    <p>
                        You hereby represent and warrant that your External Account and all Funds
                        used to purchase Digital Tokens are owned by you legally and beneficially,
                        and have not been derived from any illicit activities.
                    </p>
                    <br />
                    <p class="title">7. Orders</p>
                    <p>7.1. Application of this Section 7</p>
                    <p>
                        Section 7 applies when we receive an order from you or our Company Partners
                        in relation to the utilisation of our Services.
                    </p>
                    <br />
                    <p>7.2. Purchase Order.</p>
                    <p>
                        You may top-up your MultiExchange Account with fiat (“Fiat Top-Up”) by
                        instructing a bank transfer in any of the supported fiat currencies
                        indicated on our Partner Platforms, provided you have an active account with
                        a credit or financial institution, such credit or financial institution is
                        not located in a Restricted Jurisdiction, and such credit or financial
                        institution is supported by us or our Company Partners (each such credit or
                        financial institution, a “Permitted Institution”. Upon initiating a request
                        for a Fiat Top-Up, you will receive a payment instruction from our Company
                        Partners in relation to such Fiat Top-Up.
                    </p>
                    <p>
                        Our Fiat Top-Up service is facilitated by our Company Partners who are
                        licensed in their respective jurisdictions to process payments and custody
                        funds in accounts held in banks with whom they partner. By electing to
                        utilise the Fiat-Top Up service, you hereby agree to authorise the Company
                        and to the extent applicable, Company Partners to handle and process your
                        Fiat Top-Up instructions and hold the corresponding Funds in segregated
                        accounts maintained by our Company Partners per their applicable laws and
                        regulations.
                    </p>

                    <p>
                        For the purposes of the Fiat Top-Up, you may be issued a virtual IBAN
                        assigned to your MultiExchange Account to facilitate said service. For the
                        avoidance of doubt, the aforementioned virtual IBAN is not a customary bank
                        account and therefore, cannot be used in such manner (other than to
                        facilitate the Fiat Top-Up).
                    </p>
                    <p>
                        We do not accept or support any Fiat Top-Up instructions made by any third
                        parties. In this regard, you shall only be permitted to make a Fiat Top-Up
                        if you are the registered beneficial owner your External Account held with a
                        Permitted Institution, Digital Token Account or such other account held with
                        our Partner Platforms through which you are executing an instruction for a
                        Fiat Top-Up and MultiExchange Account. Any instructions for a Fiat Top-Up
                        shall be deemed an erroneous instruction and will be subject to relevant
                        fees, including such fees that we and/or our Company Partners may incur when
                        refunding your Funds.
                    </p>
                    <p>
                        Upon confirmation by our Company Partners that your Fiat Top-Up instruction
                        has been successfully effected, the balance of your MultiExchange will be
                        reflected on the respective Partner Platforms from whom we have received
                        your instructions.
                    </p>
                    <p>
                        For the avoidance of doubt, all Funds received pursuant to a Fiat Top-Up
                        shall only be utilised for the purposes of a Purchase Order or Fiat
                        Withdrawal.
                    </p>
                    <p>
                        All Fiat Top-Ups, once effected, are not reversible or refundable unless we
                        determine, at our discretion to do so. Further, we may, at our absolute
                        discretion, reverse, cancel void or refuse to process any Fiat Top-Up
                        instructions.
                    </p>
                    <p>
                        Subject to applicable laws and without prejudicing our rights to claim
                        further damages from you, you shall immediately indemnify us for any loss
                        and damage and expenses arising from any mistaken, erroneous or fraudulent
                        Fiat Top-up instructions (which may include but are not limited to,
                        depositing to a wrong recipient bank account, transactions made from or to
                        an External Account that is not Permitted Institution or transfers that
                        contravene any Additional Rules) (collectively, “Erroneous Top-Up
                        Instructions”) given by you or sent from your MultiExchange Account/account
                        held on respective Partner Platforms from which a Top-Up instruction is
                        sent,
                    </p>
                    <p>
                        The erroneous or fraudulent nature of your instruction shall be determined
                        by us based on information available to us. If we are required to return any
                        Funds to its origin due to any Erroneous Top-up Instructions; (a) we are
                        entitled to charge an administration fee; (b) any fees incurred from such
                        return shall be borne by you entirely; (c) you shall reimburse us such fees
                        immediately upon our request; and (d) Funds will only be remitted to the
                        bank account from which such funds were originally received.
                    </p>
                    <br />
                    <p>7.3. Fiat Withdrawal</p>
                    <p>
                        You may withdraw Funds from your MultiExchange Account to an External
                        Account held at a Permitted Institution (insofar as such External Account is
                        the same account as which you had successfully made a Fiat Top-Up {the
                        “Originating Account”}) unless otherwise approved by the Company (such
                        service, the “Fiat Withdrawal”). Our Fiat Withdrawal service is facilitated
                        by our Company Partners who are licensed in their respective jurisdictions
                        to process payments and custody funds in accounts held in banks with whom
                        they partner. By electing to utilise the Fiat Withdrawal service, you hereby
                        agree to authorise the Company and to the extent applicable, Company
                        Partners to handle and process your Fiat Withdrawal instructions. You shall
                        only be permitted to make a Fiat Withdrawal if you are the registered
                        beneficial owner your Originating Account, Digital Token Account or such
                        other account held with our Partner Platforms through which you are
                        executing an instruction for a Fiat Withdrawal and MultiExchange Account. We
                        may require to provide additional information in the event that the External
                        Account which you wish to receive your Funds differs from the Originating
                        Account.
                    </p>
                    <p>
                        Fiat Withdrawals are not reversible or refundable, unless we determine, at
                        our discretion to do so. In addition, we may, at our discretion, reverse,
                        cancel, void or refuse to process any Fiat Withdrawals. Subject to
                        applicable laws and without prejudicing our rights to claim further damages
                        from you, you shall immediately indemnify us for any loss and damage and
                        expenses arising from any mistaken, erroneous or fraudulent Fiat Withdrawal
                        instructions (which may include but are not limited to, depositing to a
                        wrong recipient bank account, transactions pertinent to unauthorised or
                        fraudulent transfers, transfers made to a closed or blocked bank account
                        regardless whether such account is the Originating Account, any transactions
                        that contravene Additional Rules) (collectively "Erroneous Withdrawal
                        Instructions"). The erroneous or fraudulent nature of your instruction shall
                        be determined by us based on reasonable evidences. You further agree that if
                        any Fiat Withdrawal is required to be reversed due to any Erroneous
                        Withdrawal Instructions: (a) we are entitled to charge an administration
                        fee; and (b) any other fees incurred from such reversal shall be borne by
                        you entirely.
                    </p>
                    <br />
                    <p>7.4. Purchase Order</p>
                    <p>
                        A Purchase Order is created when you provide an instruction to Company
                        Partners (through the Partner Platforms) to buy a specified quantity of a
                        type of Digital Token at a price quoted on Partner Platforms. To place an
                        order, you must have a sufficient amount of the relevant Funds in your
                        External Account, MultiExchange Account or such other account acceptable to
                        us and/or our Company Partners to cover the total value of the order plus
                        any applicable fees (as described in Section 9 below).
                    </p>
                    <p>
                        When you submit a Purchase Order, and it is initiated, you will be deemed to
                        have authorized the Company to execute such order on a spot basis and charge
                        you any applicable fees (as described in Section 9 below).
                    </p>
                    <p>
                        For the avoidance of doubt, any Purchase Order that has been filled may not
                        be cancelled by you. Regardless, we reserve the right to cancel any Purchase
                        Order at our sole and absolute discretion and without providing any reason
                        to you thereof Once a Purchase Order has been successfully complete, and we
                        have received the relevant Funds, we will transfer the relevant Digital
                        Tokens into the Digital Token Account designated in your Purchase Order. In
                        the event that any transfer of Funds from your External Account fails for
                        any reason whatsoever, we reserve the right to instruct our Company Partners
                        to initiate the return of the Digital Tokens and/or invoice you/your
                        External Account for any fees that we may incur due to the aforesaid.
                    </p>
                    <br />
                    <p>7.5. Sale Orders</p>
                    <p>
                        A Sale Order is created when you provide an instruction to Company Partners
                        via your Digital Token Account or on Company Partner Platforms for the sale
                        of your Digital Tokens held therein. Any Sale Order shall be subject to the
                        terms and conditions set out on Company Partner Platforms or such other
                        platforms on which you have a Digital Token Account. To place a Sale Order,
                        you must have a sufficient amount of the relevant Digital Tokens (plus gas
                        fees) in your Digital Token Account. Once a Sale Order has been initiated,
                        we will provide you with a quotation of the price which we will purchase
                        your Digital Tokens (and the corresponding time frame within which you shall
                        accept such quotation). We shall not be obliged to provide you with a
                        similar or such other favourable quotation once the aforementioned
                        acceptance period has lapsed. Upon your acceptance of our sale quotation,
                        you shall transfer the Digital Tokens from your Digital Token Account to the
                        designated digital wallet (as indicated in our confirmation of the sale
                        Order). All Sale Orders, once confirmed by you, are binding and may not be
                        cancelled by you. Notwithstanding the foregoing, we reserve the right to
                        cancel any Sale Order due to any prevailing market situation or factors at
                        the point of the Sale Order that may affect such Sale Order. These factors
                        include without limitation, any blockchain network event or such other event
                        that may result in extreme volatility and/or network congestion in relation
                        to the underlying Digital Tokens that you wish to sell pursuant to a Sale
                        Order. We shall only proceed to fulfil a Sale Order if we receive
                        instructions from our Company Partners that you possess sufficient Digital
                        Tokens viz. your Digital Token Account or on Company Partner Platforms Once
                        we have received confirmation of the aforesaid, we will process your Sale
                        Order and transfer to your MultExchange Account, the corresponding amount of
                        fiat currency once we have received the Digital Tokens (which are subject to
                        a sale), in our Digital Token Account. For the avoidance of doubt, we are
                        under no obligation to transfer to you, any fiat currencies, if we do not
                        receive the corresponding Digital Tokens. You shall be solely liable for any
                        failure of receipt of the Digital Tokens by us, and correspondingly, we
                        shall not be liable for any loss of Digital Tokens or such other amounts
                        incurred by you, due to a failed Sale Order.
                    </p>
                    <br />
                    <p>7.6. Market rates.</p>
                    <p>
                        7.6.1. You acknowledge and agree that the exchange rate information made
                        available via our Services may differ from prevailing exchange rates made
                        available via other sources outside of the Services (or as provided by our
                        Company Partners). In this regard, the rates quoted to you may differ from
                        time to time, depending on the volume of the Digital Tokens you wish to
                        purchase or such other factors that our Company Partners may determine). For
                        more information as to the available rates, you may contact us via the
                        contact details listed on our website.
                    </p>
                    <p>
                        7.6.2. Further, you acknowledge that the Company is merely transmitting
                        information to you from external sources and shall not be liable for any
                        inaccuracy or defect of such information. In this regard, by electing to
                        proceed with the Services, you represent and warrant that you have done your
                        own due diligence as to the relevant market prices from sources outside of
                        the Company and/or Company Partners.
                    </p>
                    <br />
                    <p>7.7. Market Volatility</p>
                    <p>
                        7.7.1. Particularly during periods of high volume, illiquidity, fast
                        movement or volatility in the marketplace for any particular, or one or more
                        Digital Tokens, the actual market rate at which an order is executed may be
                        different from the prevailing rate indicated at the time of your order. You
                        understand that we are not liable for any such price fluctuations.
                    </p>
                    <p>
                        7.7.2. In the event of a market disruption or Force Majeure event (as
                        defined in Section 22), the Company may do one or more of the following: (a)
                        suspend access to the Services; or (b) prevent you from completing any
                        actions via the Services, including closing any open positions. Following
                        any such event, when trading resumes, you acknowledge that prevailing market
                        rates may differ significantly from the rates available prior to such event.
                    </p>
                    <br />
                    <p>7.8 Order Fulfilment</p>
                    <p>
                        Subject to the terms and conditions in these Terms, we will use commercially
                        reasonable efforts to fulfil all orders initiated.
                    </p>
                    <br />
                    <p>7.9 Error Correction Attempts</p>
                    <p>
                        7.9.1. A Manifest Error is an error or omission, which by fault of you or us
                        or any user or third party or due to any disruptions, errors, distortions or
                        delays, is materially and clearly incorrect when taking into account market
                        conditions, rates and prices which prevailed at that time. It may include,
                        but is not limited to, an incorrect price, date, time, Digital Token
                        liquidity, fee, or any error or lack of clarity of any information or
                        source.
                    </p>
                    <p>
                        7.9.2 If an order or transfer is based on a Manifest Error (regardless of
                        whether you or we or any other user gains from the error) and/or executed
                        and/or settled on the basis of Manifest Error, The Company may (but is not
                        obliged to), at its option and sole discretion, act reasonably and in good
                        faith to: (a) correct, reverse or cancel any order or transfer; (b) void an
                        order as if it had never taken place; and/or (b) amend an order so that its
                        terms are the same as the order which would have been executed if there had
                        been no Manifest Error.
                    </p>
                    <p>
                        7.9.3. We will exercise the foregoing rights as soon as reasonably
                        practicable after we become aware of the Manifest Error. To the extent
                        practicable we will give you prior notice of any action we take under this
                        clause but if this is not practicable, we will give you notice as soon as
                        practicable afterwards. If you consider that an order or transfer is based
                        on a Manifest Error, then you must notify us immediately. We will consider
                        in good faith whether it is appropriate to take any action under this
                        Section 7.7 taking into account all the information relating to the
                        situation.
                    </p>
                    <p>
                        7.9.4. You hereby authorize the Company (and to the extent applicable,
                        Company Partners) to attempt and/or perform any such correction, reversal,
                        cancellation, voiding or amendment described in this section. The Company
                        (and to the extent applicable, Company Partners) provides no guarantee or
                        warranty that any such attempt will be successful and will have no
                        responsibility or liability for the Manifest Error or the making or failure
                        of any correction attempt, except if caused by the gross negligence, wilful
                        default or fraud of the Company. There is a risk that you may be prejudiced
                        by any action or omission of the Company (and to the extent applicable,
                        Company Partners) in this regard, and you accept that you have no recourse
                        whatsoever against The Company (and to the extent applicable, Company
                        Partners).
                    </p>
                    <br />
                    <p>7.10. Independent Relationship, No Advice.</p>
                    <p>
                        7.10.1. You acknowledge and agree to the extent applicable that: (a) The
                        Company is not holding monies and/or Funds as your trustee, and is not
                        acting as your broker, intermediary, agent, or advisor or in any fiduciary
                        capacity, and (b) no communication or information provided to you by the
                        Company shall be considered or construed as investment advice.
                    </p>
                    <p>
                        7.10.2. We shall not give advice to you on the merits of any Digital Token
                        and shall deal with you on an execution-only basis. None of our employees or
                        staff are authorised by us to give you investment advice. Accordingly, you
                        should not regard any proposed purchases, suggested trading strategies or
                        other written or oral communications from us as investment recommendations
                        or advice or as expressing our view as to whether a particular Digital Token
                        is suitable for you or meets your financial objectives. You must rely on
                        your own judgement for any decision you make in relation to your
                        MultiExchange Account and the sale/purchase of Digital Tokens. If you
                        require investment or tax advice, please contact an independent investment
                        or tax adviser. You acknowledge and agree that you have made your own
                        independent analysis and decision when making a purchase order and such
                        order has been made without reliance upon any views, representations
                        (whether written or oral), advice, recommendation, information or other
                        statement by us.
                    </p>
                    <br />
                    <p>7.11 No Custody or Possession</p>
                    <p>
                        7.11.1. The Company neither represents nor warrants that it provides or
                        shall provide at any time, any custodial services for Digital Tokens or
                        Funds, whether held in your Digital Token Account, External Account, Company
                        Partner Platforms or any other accounts which you may utilise to hold your
                        Digital Tokens and/or Funds, save for such Funds that may be held in your
                        MultiExchange Account in accordance with our Services and these Terms.
                    </p>
                    <p>
                        7.11.2. The Company shall act only as a counterparty and where applicable,
                        facilitator, for the sale and/or purchase of Digital Tokens in exchange for
                        Funds and will not possess or hold your Digital Tokens or Funds on your
                        behalf in relation to any Purchase Order and/or Sale Order
                    </p>
                    <p>
                        7.11.3. Where Funds are deposited into your MultiExchange Account, such
                        Funds are held by our Company Partners You hereby acknowledge your
                        understanding that your MultiExchange Account merely facilitates Purchase
                        Orders and/or Sale Orders and is not inclusive of your Digital Token
                        Account. The Digital Token Accounts are hosted/operated by Company Partners
                        and third-party service providers, and not The Company. In this regard, you
                        hereby acknowledge that The Company shall not be liable for any loss of
                        Digital Tokens or Funds or that may be held in your Digital Token Accounts
                        or on Company Partner Platforms.
                    </p>
                    <p>
                        7.11.4 The legal and beneficial title of any Funds and/or Digital Tokens
                        that have been successfully transferred to us in lieu of a Purchase Order
                        and/or Sale Order shall remain with us and/or our Affiliates (where
                        applicable).
                    </p>
                    <br />
                    <p>7.12 No Refunds</p>
                    For the avoidance of doubt, all transactions conducted via the Services are
                    final and non-refundable unless otherwise determined by the Company in its sole
                    and absolute discretion, taking into consideration, all facts and evidence
                    presented and upon consultation with Company Partners. Should you have any
                    enquiries/issues or otherwise, in relation to any transactions conducted viz.
                    the Services, kindly contact our customer support team at contact@saintpay.com.
                    <p></p>
                    <br />
                    <p class="title">8. Risk Disclosure</p>
                    <p>Please refer to the Risk Disclosure Statement (Annex 2).</p>
                    <br />
                    <p class="title">9. Fees</p>
                    <p>
                        9.1. Amount of Fees. You agree to pay The Company the fees that may be
                        required to be paid and which shall be specified from time to time on our
                        website or at the point an order is made. The Company may, in its
                        discretion, update the fees at any time. Any updated fees will apply to
                        orders that occur after the effective time of the updated fees. You
                        authorize The Company to instruct our Company Partners or your External
                        Account service provider to deduct any applicable fees that you owe under
                        these Terms.
                    </p>
                    <br />
                    <p>
                        9.2. Third-Party Fees. In addition to the Fees, your External Account
                        provider and/or our Company Partners may impose fees in connection with your
                        use of your designated External Account, Digital Token Account, Company
                        Partner Platforms or such other services they may provide which are
                        ancillary to the Services. Any fees imposed by your External Account
                        provider and/or Company Partners will not be subject to their respective
                        terms and conditions. You are solely responsible for paying any fees imposed
                        by our Company Partners or your External Account provider.
                    </p>
                    <br />
                    <p>
                        9.3. Payment of Fees. You authorize us to instruct Company Partners and/or
                        External Account providers to charge or deduct any applicable Fees owed via
                        the Services, from your External Account and/or Digital Token Account.
                    </p>
                    <br />
                    <p class="title">10. Electronic Notices</p>
                    <p>
                        10.1. Consent to Electronic Delivery. You agree and consent to receive
                        electronically all communications, agreements, documents, receipts, notices
                        and disclosures (collectively, “Communications”) that the Company provides
                        in connection with your MultiExchange Account and/or use of the Services.
                        You agree that the Company may provide these Communications to you by
                        posting them via the Services, by emailing them to you at the email address
                        you provide, or by publishing them on our website (in this regard, you shall
                        be liable for keeping yourself up to date on all developments and undertake
                        to keep yourself up to date). You should maintain copies of electronic
                        Communications by printing a paper copy or saving an electronic copy. You
                        may also contact us through support to request additional electronic copies
                        of Communications or, for a fee, paper copies of Communications (as
                        described below).
                    </p>
                    <br />
                    <p>
                        10.2. Hardware and Software Requirements. In order to access and retain
                        electronic Communications, you will need a computer with an Internet
                        connection that has a current web browser with cookies enabled and 128-bit
                        encryption. You will also need to have a valid email address on file with
                        the Company and have sufficient storage space to save past Communications or
                        an installed printer to print them.
                    </p>
                    <br />
                    <p>
                        10.3. Withdrawal of Consent. You may withdraw your consent to receive
                        electronic Communications by contacting us. If you decline or withdraw
                        consent to receive electronic Communications, the Company may suspend or
                        terminate your use of the Services.
                    </p>
                    <br />
                    <p>
                        10.4. Requesting Paper Copies. If, after you consent to receive
                        Communications electronically, you would like a paper copy of a
                        Communication we previously sent you, you may request a copy within 30 days
                        after the date we provided the Communication to you by contacting us. In
                        order for us to send paper copies to you, you must have a current street
                        address on file with the Company. Please note that the Company operates
                        exclusively online and it is very burdensome for us to produce paper copies
                        of Communications. Therefore, if you request paper copies, you understand
                        and agree that the Company may charge you a processing fee, in the amount
                        described in the Fee Structure, for each page of Communication requested.
                    </p>
                    <br />
                    <p>
                        10.5. Updating Contact Information. It is your responsibility to keep your
                        email address and personal details on file with the Company up to date so
                        that the Company can communicate with you. You understand and agree that if
                        the Company sends you an electronic Communication but you do not receive it
                        because your personal details on file are incorrect, out of date, blocked by
                        your service provider, or you are otherwise unable to receive electronic
                        Communications, the Company will be deemed to have provided the
                        Communication to you. Please note that if you use a spam filter that blocks
                        or re-routes emails from senders not listed in your email address book, you
                        must add the Company to your email address book so that you will be able to
                        receive the Communications we send to you. You can update your personal
                        details at any time by logging into your SaintPay Account or by contacting
                        us. If your email address becomes invalid such that electronic
                        Communications sent to you by the Company are returned, the Company may deem
                        your account to be inactive, and you may not be able to complete any
                        transaction via our Services until we receive a valid, working email address
                        from you. We shall not be liable for any failure of communication (for any
                        reason).
                    </p>
                    <br />
                    <p class="title">11. Disputed Property</p>
                    <p>
                        11.1. If the Company receives notice that any Digital Tokens held in your
                        Digital Token Account or Funds held in your External Account are alleged to
                        have been stolen or otherwise are not lawfully possessed by you, the Company
                        may, but has no obligation to, place or instruct our Company Partners/your
                        External Account provider to place an administrative hold on or freeze, the
                        affected Digital Tokens in your Digital Token Account, the Funds received
                        for the purposes of the Services in your SaintPay Account, or where
                        applicable, your External Account.
                    </p>
                    <br />
                    <p>
                        11.2. If The Company or Company Partners do place an administrative hold on
                        or freeze some or all of your Digital Tokens or Funds, the Company or
                        Company Partners (upon instruction of The Company) may continue such hold
                        the same until such time as the dispute has been resolved and evidence of
                        the resolution acceptable to has been provided in a form acceptable to the
                        Company (and/or Company Partners, where applicable). The Company (and/or
                        Company Partners, where applicable) will not involve itself in any such
                        dispute or the resolution of the dispute.
                    </p>
                    <br />
                    <p>
                        11.3. You agree that the Company (and/or Company Partners, where applicable)
                        will have no liability or responsibility for any losses, damages or
                        prejudice arising from or in connection with such hold or freeze, or for
                        your inability to withdraw Digital Tokens or receive a refund for your
                        Funds, during the period of any such hold or freeze.
                    </p>
                    <br />
                    <p class="title">12. Acceptable Use</p>
                    <p>
                        12.1. When accessing or using the Services, you agree that you will not
                        violate any law or any contractual, intellectual property or other
                        third-party right or commit a tort, and that you are solely responsible for
                        your conduct while using ourServices. Without limiting the generality of the
                        foregoing, you agree that you will not:
                    </p>
                    <p>
                        a) Use our Services in any manner that could interfere with, disrupt,
                        negatively affect or inhibit other users from fully enjoying our Services,
                        or that could damage, disable, overburden or impair the functioning of our
                        Services or bring disrepute to our Services in any manner;
                    </p>
                    <p>
                        b) Use our Services to pay for, support or otherwise engage in any illegal
                        gambling activities; fraud; market manipulation or abuse (including but not
                        limited to your taking actions, or acting in concert with another user to
                        take actions, on or outside the Services, which are intended to deceive or
                        mislead other users, or artificially control or manipulate the price or
                        trading volume of a Digital Token); money-laundering; or terrorist
                        activities; or other illegal activities;
                    </p>
                    <p>
                        c) Use any robot, spider, crawler, scraper or other automated means or
                        interface not provided by us to access our Services or to extract data;
                    </p>
                    <p>d) Use or attempt to use another user’s account without authorization;</p>
                    <p>
                        e) Attempt to circumvent any content filtering techniques we employ, or
                        attempt to access any service or area of our Services that you are not
                        authorized to access; Introduce to the Services any malware, virus, trojan
                        worms, logic bombs, or other harmful material;
                    </p>
                    <p>
                        f) Develop any third-party applications that interact with our Services
                        without our prior written consent;
                    </p>
                    <p>g) Provide false, inaccurate, or misleading information;</p>
                    <p>
                        h) Encourage or induce any third party to engage in any of the activities
                        prohibited under this section.
                    </p>
                    <br />
                    <p class="title">13. Feedback</p>
                    <p>
                        13.1. We will own exclusive rights, including all intellectual property
                        rights, to any feedback, suggestions, and ideas or other information or
                        materials regarding the Company or our Services that you provide, whether by
                        email, posting through our Services or otherwise (“Feedback”).
                    </p>
                    <br />
                    <p>
                        13.2. Any Feedback you submit is non-confidential and shall become the sole
                        property of the Company. We will be entitled to the unrestricted use and
                        dissemination of such Feedback for any purpose, commercial or otherwise,
                        without acknowledgment or compensation to you. You waive any rights you may
                        have to the Feedback (including any copyrights). Do not send us Feedback if
                        you expect to be paid or want to continue to own or claim rights in them;
                        your idea might be great, but we may have already had the same or a similar
                        idea and we do not want disputes.
                    </p>
                    <br />
                    <p>
                        13.3. You also acknowledge and agree that we have the right to disclose your
                        identity to any third party who is claiming that any content posted by you
                        constitutes a violation of their intellectual property rights, or of their
                        right to privacy. We have the right to remove any posting you make on our
                        website if, in our opinion, your post does not comply with the content
                        standards set out in our website.
                    </p>
                    <br />
                    <p class="title">14. Copyrights and Other Intellectual Property Rights</p>
                    <p>
                        14.1. Unless otherwise indicated by us, all copyright and other intellectual
                        property rights in all content and other materials contained on our website
                        or provided in connection with the Services, including, without limitation,
                        all designs, text, graphics, pictures, information, data, software, sound
                        files, other files and the selection and arrangement thereof (collectively,
                        “Materials”) are the proprietary property of the Company, Company Partners
                        or our licensors or suppliers and are protected by international copyright
                        laws and other intellectual property rights laws.
                    </p>
                    <br />
                    <p>
                        14.2. We hereby grant you a limited, nonexclusive and non-sublicensable
                        license to access and use the Materials for your personal use. Such license
                        is subject to these Terms and does not permit (a) any resale of the
                        Materials; (b) the distribution, public performance or public display of any
                        Materials; (c) modifying or otherwise making any derivative uses of the
                        Materials, or any portion thereof; or (d) any use of the Materials other
                        than for their intended purposes. The license granted under this section
                        will automatically terminate if we suspend or terminate your access to the
                        Services.
                    </p>
                    <br />
                    <p class="title">15. Company Partners</p>
                    <p>
                        15.1. Prior to or at the point of utilising the Services, you shall
                        separately read and sign User Agreements, Terms and Conditions, Privacy
                        Policies, etc. with our Company Partners (as applicable, in accordance to
                        the Company Service which you require) and other specific rules as well as
                        the business rules of such Company Partners and open an account or Digital
                        Token Accounts on such Company Partner Platforms (as applicable), following
                        the completion of the registration and identity verification for your
                        MultiExchange Account, if applicable.
                    </p>
                    <br />
                    <p>
                        15.2. By proceeding with our Services, you hereby represent and warrant that
                        you have read all terms, rules and policies imposed by our Company Partners
                        and agree to be bound by the same. In this regard, you acknowledge that the
                        Company, Company Partners and External Account providers
                    </p>
                    <br />
                    <p class="title">16. Suspension; Termination</p>
                    <p>
                        16.1. In the event of any Force Majeure Event (as defined in Section 22.5),
                        breach of these Terms or any laws or regulations, or any other event that
                        would make provision of the Company Services commercially unreasonable for
                        the Company, we may, in our discretion and without liability to you, with or
                        without prior notice, suspend your access to all or a portion of our
                        Services and/or as may be required by applicable laws, file a suspicious
                        transaction report with the relevant authorities or otherwise report or
                        inform the relevant authorities or take any other steps to protect our
                        interests as we deems appropriate.
                    </p>
                    <br />
                    <p>
                        16.2 We may also terminate your access to the Services in our sole
                        discretion, immediately and without prior notice, and delete or deactivate
                        your MultiExchange Account and all related information and files in such
                        account without liability to you, including, for instance, in the event that
                        you breach any term of these Terms.
                    </p>
                    <br />
                    <p>
                        16.3. In the event of discontinuation of all Services or termination of your
                        access to the Services or deletion or deactivation of your MultiExchange
                        Account: (a) all amounts payable by you to the Company will immediately
                        become due; (b) the Company may cancel any open orders or other transaction
                        requests that are pending at the time of discontinuation or termination or
                        deletion or deactivation of your MultiExchange Account;(c) the Company will,
                        where possible, return any Funds which we have collected for the purposes of
                        our Services which have yet to be transacted but not otherwise owed to The
                        Company or Company Partners, unless prohibited by applicable laws or
                        regulations or by order of law enforcement or governmental authority, or the
                        Company believes you have committed fraud, negligence or other misconduct;
                        and/or (d) the Company may take such other steps as the Company deems
                        necessary or desirable to protect its own interests.
                    </p>
                    <br />
                    <p>
                        16.4. We are not and shall not be responsible or liable for any loss or
                        damages incurred as a result of or arising from any actions taken under this
                        section.
                    </p>
                    <br />
                    <p class="title">17. Discontinuance of Services</p>
                    <p>
                        17.1. We may, in our sole discretion and without liability to you, with or
                        without prior notice and at any time, modify or discontinue, temporarily or
                        permanently, all or any portion of our Company Services, which may include
                        prohibiting use of the Services in or from certain jurisdictions.
                    </p>
                    <br />
                    <p>
                        17.2. Unless the Company notifies you or makes a public statement to the
                        contrary, the Company does not support digital wallets or any platforms that
                        are not provided by our Company Partners and shall have no responsibility or
                        liability whatsoever for any losses or damages any order executed by you
                        with said digital wallets and/or platforms.
                    </p>
                    <br />
                    <p class="title">18. Disclaimer of Warranties</p>
                    <p>
                        18.1. Except as expressly provided to the contrary in a writing by us, our
                        services are provided on an “As is” and “As available” basis. We expressly
                        disclaim, and you waive, all warranties of any kind, whether express or
                        implied, including, without limitation, implied warranties of
                        merchantability, fitness for a particular purpose, title and
                        non-infringement as to our services, including the information, content and
                        materials contained therein, to the fullest extent permitted by applicable
                        laws.
                    </p>
                    <br />
                    <p>
                        18.2. You acknowledge that information you store or transfer through our
                        services may become irretrievably lost or corrupted or temporarily
                        unavailable due to a variety of causes, including software failures,
                        protocol changes by third party providers, internet outages, force majeure
                        event or other disasters including third party Distributed Denial of Service
                        attacks, scheduled or unscheduled maintenance, or other causes either within
                        or outside our control. You are solely responsible for backing up and
                        maintaining duplicate copies of any information you store or transfer
                        through our Services.
                    </p>
                    <br />
                    <p>
                        18.3. Some jurisdictions do not allow the disclaimer of implied terms in
                        contracts with consumer, so some or all of the disclaimers in this section
                        may not apply to you.
                    </p>
                    <br />
                    <p class="title">19. Limitation of Liability</p>
                    <p>
                        19.1. Except as otherwise required by law, in no event shall the Company (or
                        Company Partners where applicable), our directors, officers, members,
                        employees, agents or contractors be liable for any special, indirect or
                        consequential damages, or any other damages of any kind, including but not
                        limited to loss of use, loss of profits or loss of data, whether in an
                        action in contract, tort (including but not limited to negligence) or
                        otherwise, arising out of or in any way connected with the use of or
                        inability to use our Services or the Materials, including without limitation
                        any damages caused by or resulting from reliance by any user on any
                        information obtained from the Company or that result from mistakes,
                        omissions, interruptions, deletion of files or email, errors, defects,
                        viruses, delays in operation or transmission or any failure of performance,
                        whether or not resulting from a force majeure event, communications failure,
                        theft of, destruction of or unauthorized access to our records, programs or
                        services.
                    </p>
                    <br />
                    <p>
                        19.2. Some jurisdictions do not allow the exclusion of certain warranties or
                        the limitation or exclusion of liability for incidental or consequential
                        damages. Accordingly, some of the limitations of this section may not apply
                        to you.
                    </p>
                    <br />
                    <p>
                        19.3. To the maximum extent permitted by applicable law, in no event shall
                        the aggregate liability of the Company (or Company Partners where
                        applicable) (including our directors, officers, members, employees and
                        agents), whether in contract, warranty, tort (including negligence, whether
                        active, passive or imputed), product liability, strict liability or other
                        theory, arising out of or relating to the use of, or inability to use the
                        Services, exceed the fees paid by you to the Company during the 12 months
                        immediately preceding the date of any claim giving rise to such liability.
                    </p>
                    <br />
                    <p>
                        19.4. We have no control over, or liability for, the delivery, quality,
                        safety, legality or any other aspect of any Digital Tokens that you may
                        purchase. We are not responsible for ensuring that our Company Partners will
                        complete an order or transaction or is authorised to do so. If you
                        experience a problem with any Digital Tokens or Services purchased using the
                        Services, or if you have a dispute with our Company Partner, you should
                        resolve the dispute directly with our Company Partners.
                    </p>
                    <br />
                    <p class="title">20. Indemnity</p>
                    <p>
                        You agree to defend, indemnify and hold harmless the Company (and each of
                        our officers, directors, members, employees, agents and affiliates) from any
                        claim, demand, action, damage, loss, cost or expense, including without
                        limitation reasonable attorneys’ fees, arising out or relating to (a) your
                        use of, or conduct in connection with, our Services; (b) any Feedback you
                        provide; (c) your violation of these Terms or any agreement incorporated by
                        reference in these Terms; and/or (d) your violation of any rights of any
                        other person or entity or of any laws and regulations including but not
                        limited to anti-money laundering and countering the financing of terrorism
                        laws and regulations. If you are obligated to indemnify us, we will have the
                        right, in our sole discretion, to control any action or proceeding (at our
                        expense) and determine whether we wish to settle it.
                    </p>
                    <br />
                    <p class="title">21. Applicable Law and Disputes Resolution</p>
                    <p>
                        21.1 These Terms are drawn up in accordance with the legal acts of the
                        Republic of Italy. The legal acts of the Republic of Italy shall apply to
                        these Terms.
                    </p>
                    <br />
                    <p>
                        21.2. It is your responsibility to abide by local laws in relation to the
                        legal usage of Services in your local jurisdiction as well as other laws and
                        regulations applicable to you.
                    </p>
                    <br />
                    <p>
                        21.3. You must also factor, to the extent of their local laws all aspects of
                        taxation, the withholding, collection, reporting and remittance to their
                        appropriate tax authorities.
                    </p>
                    <br />
                    <p>
                        21.4. All users of our Services acknowledge and declare that their Funds
                        come from legitimate sources and do not originate from illegal activities.
                        Users agree that the Company will require them to provide or otherwise
                        collect the necessary information and materials as per relevant laws or
                        government orders to verify the legality of the sources and use of their
                        funds.
                    </p>
                    <br />
                    <p>
                        21.5. By accepting the Terms, you acknowledge that you aware of sanctions
                        schemes imposed by the Republic of Italy, European Union or other
                        jurisdictions to the Russia Federation, the Republic of Belarus or to other
                        countries. You agree to follow and not to violate mentioned sanctions
                        schemes while using our Services.
                    </p>
                    <br />
                    <p>
                        21.6. Any disputes between you and the Company shall first be settled by
                        negotiations. In the case of a complaint, please spell out the reason for
                        your complaint, how you would want us to address the issue, and any other
                        information you consider to be relevant. We will acknowledge receipt of your
                        complaint if you contact us.
                    </p>
                    <br />
                    <p>
                        21.7. If you want to file a complaint by email, please send it to the email
                        contact@saintpay.com
                    </p>
                    <br />
                    <p>
                        21.8. Any resolution proposal presented to you will only bind us if you
                        approve it. A resolution proposal will not indicate an acknowledgment of
                        wrongdoing or responsibility on our part regarding the subject of the
                        complaint.
                    </p>
                    <br />
                    <p>
                        21.9. If the dispute cannot be settled by negotiations, the dispute may be
                        settled in the competent court of the Republic of Law.
                    </p>
                    <br />
                    <p>
                        21.10. We may withhold your funds/assets and/or freeze your account related
                        to the dispute until the dispute is settled.
                    </p>
                    <br />
                    <p class="title">22. Miscellaneous</p>
                    <p>22.1. Entire Agreement; Order of Precedence.</p>
                    <p>
                        These Terms contain the entire agreement, and supersede all prior and
                        contemporaneous understandings between the parties regarding the Services.
                        These Terms do not alter the terms or conditions of any other electronic or
                        written agreement you may have with the Company for the Services. In the
                        event of any conflict between these Terms and any other agreement you may
                        have with the Company, the terms of that other agreement will prevail only
                        if these Terms are specifically identified and declared to be overridden by
                        such other agreement.
                    </p>
                    <br />
                    <p>22.2. Third Party Rights</p>
                    <p>
                        A person who is not a party to these Terms has no right to enforce any term
                        of these Terms.
                    </p>
                    <br />
                    <p>22.3. Amendment</p>
                    <p>
                        We reserve the right to make changes or modifications to these Terms from
                        time to time, in our sole discretion. If we make changes to these Terms, we
                        will provide you with notice of such changes, such as by sending an email,
                        providing notice on the homepage of our website and/or by posting the
                        amended Terms via the applicable websites and mobile applications and
                        updating the “Last Updated” date at the top of these Terms. The amended
                        Terms will be deemed effective immediately upon posting for any new users of
                        the Services. In all other cases, the amended Terms will become effective
                        for pre-existing users upon the earlier of either: (i) the date users click
                        or press a button to accept such changes, or (ii) continued use of our
                        Services 30 days after we provide notice of such changes. Any amended Terms
                        will apply prospectively to use of the Services after such changes become
                        effective in accordance with these Terms. If you do not agree to any amended
                        Terms, you must discontinue using our Services and contact us to terminate
                        your account.
                    </p>
                    <br />
                    <p>22.4. Waiver</p>
                    <p>
                        Our failure or delay in exercising any right, power or privilege under these
                        Terms shall not operate as a waiver thereof.
                    </p>
                    <br />
                    <p>22.5. Severability</p>
                    <p>
                        The invalidity or unenforceability of any of these Terms shall not affect
                        the validity or enforceability of any other of these Terms, all of which
                        shall remain in full force and effect.
                    </p>
                    <br />
                    <p>22.6. Force Majeure Events</p>
                    <p>
                        Neither Party shall be liable for any economic loss, delay or failure to
                        perform any part of these Terms if such loss, delay or failure is caused by
                        (but not limited): fire; flood; explosion; accident; war; strike; embargo;
                        government request; pandemic; civil or military authority; civil
                        disturbances; inaccessibility of the public Internet; hacking or
                        distribution of DoS attacks; failure to guarantee materials or labour;
                        termination of vital agreements by third parties; attacks on the security,
                        integrity or operation of Digital Tokens, the Services, SaintPay Accounts,
                        Company Partner Platforms; Digital Token Accounts or equipment or software
                        malfunction or any other cause beyond our reasonable control; actions of the
                        other Party or any other cause beyond the control of the Party. If such
                        circumstances of force majeure last for more than 3 months, either Party
                        shall have a right to terminate these Terms immediately upon written notice
                        thereof to the other Party. We do not accept any liability for
                        non-compliance with our obligations under these Terms and Conditions arising
                        from the emergency situation and / or quarantine announced by the
                        Government.
                    </p>
                    <br />
                    <p>22.7. Assignment</p>
                    <p>
                        You may not assign or transfer any of your rights or obligations under these
                        Terms without prior written consent from The Company, including by operation
                        of law or in connection with any change of control. The Company may assign
                        or transfer any or all of its rights under these Terms, in whole or in part,
                        without obtaining your consent or approval.
                    </p>
                    <br />
                    <p>22.8. Headings</p>
                    <p>
                        Headings of sections are for convenience only and shall not be used to limit
                        or construe such sections.
                    </p>
                    <br />
                    <p>22.9. Governing language and translations</p>
                    <p>
                        These Terms, the Privacy Policy, and other agreements or communications
                        notified through the Services have been drafted in English. Although
                        translations in other languages of any of the foregoing documents may be
                        available, such translations may not be up to date or complete. Accordingly,
                        you agree that in the event of any conflict between the English language
                        version of the foregoing documents and any other translations thereof, the
                        English language version of such documents shall govern and prevail.
                    </p>
                    <br />
                    <p>22.10. Survival</p>
                    <p>
                        All provisions of these Terms that, by their nature extend beyond the
                        expiration or termination of these Terms, including, but not limited to,
                        those about suspension or termination, MultiExchange Account
                        discontinuation, debts owed to the Company, the general use of the Company
                        website, disagreements with the Company, and general provisions, will remain
                        binding and in effect following the expiration or termination of these
                        Terms.
                    </p>
                    <br />
                    <p>22.11. Privacy Policy</p>
                    <p>
                        Please refer to our
                        <router-link
                            to="/Privacy"
                            class="link text-base-16px text-dark font-400 underline"
                        >
                            Privacy Policy
                        </router-link>
                        for information about how we collect, use and disclose your personal data.
                    </p>
                    <br />
                    <p class="title">ANNEX 1</p>
                    <p>Restricted Jurisdictions</p>
                    <p>
                        Afghanistan Algeria American Samoa Belarus Bolivia (Plurinational State of)
                        Central African Republic China Cuba Ecuador Guam Iran (Islamic Republic of)
                        Iraq Korea (the Democratic People's Republic of) Kosovo Lebanon Libya Mali
                        Nepal Pakistan Palestine, State of Puerto Rico Qatar Russian Federation
                        Somalia South Sudan Sudan (the) Syrian Arab Republic United States of
                        America Venezuela (Bolivarian Republic of) Virgin Islands (U.S.) Western
                        Sahara Yemen
                    </p>
                    <br />
                    <p class="title">ANNEX 2</p>
                    <p>RISK DISCLOSURE STATEMENT</p>
                    <p>
                        Users acknowledge and agree that no transaction through the Company Services
                        is free from the following risks, and The Company cannot and does not have
                        the obligation to be responsible for the following risks:
                    </p>
                    <p>
                        (1)macroeconomic risks: Users may suffer losses due to abnormal price
                        fluctuations arising from changes in the macroeconomic situation;
                    </p>
                    <p>
                        (2)policy risks: changes in relevant laws, regulations, policies and rules
                        may cause abnormal fluctuations in prices and other areas, as a result of
                        which the Users may suffer losses;
                    </p>
                    <p>
                        (3)default risks: Users may suffer losses due to the inability or
                        unwillingness of the project owner to carry out or continue carrying out
                        their project;
                    </p>
                    <p>
                        (4)risks related to earnings: digital assets are not issued by any financial
                        institution or platform, and the digital asset market is a brand new and
                        unconfirmed market that may not generate actual increase in earnings;
                    </p>
                    <p>
                        (5)trading risks: digital assets are mainly used by speculators, and are
                        used relatively less in retailing and commercial markets; the trading of
                        digital assets involves an extremely high level of risk because trading of
                        digital assets goes on uninterrupted throughout the day without any limits
                        on the ups and downs, and therefore prices are subject to large fluctuations
                        caused by traders and/or global government policies;
                    </p>
                    <p>(6)risks caused by force majeure factors;</p>
                    <p>
                        (7)User's fault: any and all losses caused by the Users’ fault, including
                        loss caused by wrong decision-making, improper operation, forgetting or
                        revealing passwords, deciphering of passwords by others, third-party
                        intrusion into computer systems used by the Users, and malicious or improper
                        operation by a third party entrusted by the Users to serve as their agent.
                    </p>
                    <p>
                        Digital asset transactions are extremely risky and are therefore not
                        suitable investments for most people. The Users know and understand that
                        part or all of their investment in digital asset trading may be lost, so the
                        Users should determine the amount of their investment or transactions based
                        on the extent of losses they can afford. In addition to the risks indicated
                        hereunder, there will also be risks that cannot be predicted.
                    </p>
                    <p>
                        Therefore, the Users should carefully assess their financial situation and
                        various risks before making any decision on investment in or transaction of
                        digital assets. The Users shall bear any and all losses arising from their
                        decision, and neither The Company nor its Company Partners shall bear any
                        responsibility for Users' investment decisions.
                    </p>
                    <p>
                        In view of the risks arising from digital asset investment or transaction,
                        if a User has any doubts as to such investment or transactions, the User
                        should seek the assistance of professional consultants prior to transaction
                        or investment.
                    </p>
                    <p>
                        Neither The Company nor its Company Partners provide any guarantee or
                        condition to any User and/or any transaction, whether express, implied or
                        statutory. The Company cannot and does not attempt to control the
                        information released to Users by project owners.
                    </p>
                    <p>
                        The Company does not undertake any form of certification and authentication
                        services for such information.
                    </p>
                    <p>
                        The Company does not make any express or implied guarantee for its Users to
                        use the Company Services, including but not limited to the applicability,
                        absence of errors or omissions, continuity, accuracy, reliability and
                        suitability for a particular purpose. Furthermore, The Company does not make
                        any undertaking and guarantee in connection with the validity, accuracy,
                        correctness, reliability, quality, stability, integrity and timeliness of
                        the technologies and information involved in the services provided by the
                        Company Partners.
                    </p>
                    <p>
                        Whether to use the Company Services (or Company Partner Platforms) is the
                        personal decision of individual Users, who shall exclusively bear any and
                        all risks and possible losses that may arise from such decision.
                    </p>
                    <p>
                        The Company does not make any express or implied guarantee in connection
                        with the market, value and price of Digital Tokens. The Users know and
                        understand the instability of the Digital Token market. The price and value
                        of Digital Tokens may fluctuate significantly or collapse at any time.
                        Engaging in any transaction pertaining to Digital Tokens is the sole and
                        free choice and decision by individual Users, who shall exclusively bear the
                        risks and possible losses that may arise.
                    </p>
                    <p>
                        This Terms and Risk Disclosure Statement do not reveal all the risks and
                        market situations that the Users may be subject to when they engage in
                        Company Services or any transactions on Company Partner Platforms.
                    </p>
                    <p>
                        Before making any decision, the Users should fully understand the relevant
                        digital assets, adopt a cautious approach to decision-making based on their
                        own transaction objectives, risk tolerance capacity and asset status, and
                        assume all risks exclusively on their own.
                    </p>
                </div>
            </div>
        </div>
        <footer-bar :footer="footerList" />

        <!-- jump to the top -->
        <button
            v-if="showScrollBtn"
            @click="goTop"
            class="fixed bottom-24px right-24px bg-dark w-40px h-40px rounded-10px flex items-center justify-center hover:(cursor-pointer bg-primary) active:bg-secondary"
        >
            <img :src="require('../../img/chever.svg')" />
        </button>
    </div>
</template>
<script>
import NavBar from '@/components/NavBar/index.vue'
import { staticData } from '../data.js'

export default {
    name: 'Privacy',
    components: {
        NavBar,
        footerBar: () => import('./Footer/index.vue')
    },
    mounted() {
        window.addEventListener('scroll', this.scrollPage)
        this.goTop()
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.scrollPage)
    },
    data() {
        return {
            ...staticData,
            showScrollBtn: false
        }
    },
    methods: {
        scrollPage() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                this.showScrollBtn = true
            } else {
                this.showScrollBtn = false
            }
        },
        // to scroll up to the page
        goTop() {
            document.body.scrollTop = 0 // For Safari
            document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
        }
    }
}
</script>
<style></style>
